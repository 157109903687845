import React from 'react';
import './style/Footer.scss';
import { Button } from 'reactstrap';
import { fetchPost } from '../../toolbox/fetchlib';
import { useHistory } from 'react-router';

const logout_call = "/api/logout"

export default function Footer(props) {

    const history = useHistory();

    const logout = () => {
        fetchPost(logout_call, {}, (data) => {
            history.push('login');
        }, (er) => { });
    }

    return (
        <div id="footer">
            <div id="footer-content">
                <Button className="footer-btn"
                    type="button"
                    onClick={() => window.open("https://service.solesta.nl/smartline/", '_blank').focus()}>
                    Informatie
                </Button>
                <Button className="footer-btn"
                    type="button"
                    onClick={() => props.logout_btn ? logout() : 'changeUserInfo' in props ? props.changeUserInfo() : ""}>
                    {props.logout_btn ? "Uitloggen" : props.back ? "Mijn systeem" : "Mijn profiel"}
                </Button>
            </div>
        </div>
    )

}