import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Button, FormGroup, Input, Spinner } from 'reactstrap';

import logo from './../../assets/img/solesta-logo.png';
import { fetchPost } from '../../toolbox/fetchlib';

const reset_api = "/api/change_forgotten_pass"

const useStyles = makeStyles({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    logo: {
        width: '98%',
        maxWidth: 300
    },
    fields: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '5vh',
        width: 220
    },
    new_acc_btn: {
        marginTop: '5vh'
    }
});

export default function ResetPassword(props) {

    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const [token, setToken] = useState("");

    const [pw_1, setPw1] = useState("");
    const [pw_2, setPw2] = useState("");

    useEffect(() => {
        var url = window.location.href;
        var index = url.indexOf('?token=');
        if (index >= 0)
            setToken(url.substr(index + 7, url.length));
    }, [])

    const sendEmail = () => {
        if (pw_1 === pw_2)
            fetchPost(reset_api, { token: token, new_password: pw_1 }, (data) => {
                setMessage("success");
            }, (er) => { setMessage("error") })
        else setMessage("not_equal");
    } //  

    return (
        <div className={classes.root}>
            <img src={logo} className={classes.logo} alt="logo" />
            <div className={classes.fields}>
                <Typography style={{ textAlign: 'center', marginBottom: 10 }}>
                    {message === "not_equal" ? "Wachtwoorden komen niet overeen" :
                        message === "criteria" ? "Wachtwoord moet minimaal 7 tekens bevatten waaronder een hoofdletter, cijfer en speciaal teken." :
                            message === "success" ? "Opgeslagen. Druk op de knop om in te loggen." : ""}
                </Typography>
                {message !== "success" &&
                    <FormGroup>
                        <Input
                            id="uname"
                            value={pw_1}
                            onChange={(v) => setPw1(v.target.value)}
                            placeholder="Nieuw wachtwoord"
                            type="password"
                        />
                    </FormGroup>}
                {message !== "success" &&
                    <FormGroup>
                        <Input
                            id="uname"
                            value={pw_2}
                            onChange={(v) => setPw2(v.target.value)}
                            placeholder="Herhaal wachtwoord"
                            type="password"
                        />
                    </FormGroup>}
                {message !== "success" &&
                    <Button color="warning"
                        type="button"
                        disabled={loading}
                        style={{ width: '100%' }}
                        onClick={() => sendEmail()}>
                        {loading ? <Spinner style={{ width: 21, height: 21 }} /> : "Opslaan"}</Button>}
                {message === "success" &&
                    <Button color="warning"
                        type="button"
                        disabled={loading}
                        style={{ width: '100%' }}
                        onClick={() => history.push("/login")}>
                        {loading ? <Spinner style={{ width: 21, height: 21 }} /> : "Naar login"}</Button>}
            </div>

        </div>
    )
}