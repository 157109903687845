import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, FormGroup, Input, Modal } from 'reactstrap';
import { Dropdown } from '../../../assets/components/CustomItems';
import { jsonEmpty } from '../../../toolbox/utils';

const useStyles = makeStyles({
    creds_fields: {
        width: '100%',
        maxWidth: 300,
        margin: '30px auto 0'
    },
    system_name: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    name: {
        width: 150
    },
    tn_fields: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tn_1: {
        width: 65
    },
    tn_2: {
        width: 60
    },
    tn_3: {
        width: 75
    },
    b2: {
        fontSize: '13px !important'
    }
})

export default function SystemEditor(props) {

    const classes = useStyles();

    const [existing, setExisting] = useState(false);

    const [error, setError] = useState(false);
    const [client_id, setClientId] = useState("");
    const [installer, setInstaller] = useState("");
    const [tn_1_col, setTn1Col] = useState("");
    const [tn_2_col, setTn2Col] = useState("");
    const [tn_3_col, setTn3Col] = useState("");
    const [tn_1_op, setTn1Op] = useState("");
    const [tn_2_op, setTn2Op] = useState("");
    const [tn_3_op, setTn3Op] = useState("");
    const [tn_1_pomp, setTn1Pomp] = useState("");
    const [tn_2_pomp, setTn2Pomp] = useState("");
    const [tn_3_pomp, setTn3Pomp] = useState("");

    useEffect(() => {
        var s = props.system;
        if (typeof s !== 'undefined' && s !== null && !jsonEmpty(s) && !existing) {
            setClientId(s.client_id || "");
            for (var i = 0; i < props.installers.length; i++) {
                if (props.installers[i].id === (s.installer || ""))
                    setInstaller(i);
            }
            if ("trace_number" in s) {
                var col = (s.trace_number.collector || "").split(/[/\-_]/);
                var op = (s.trace_number.opslag || "").split(/[/\-_]/);
                var pomp = (s.trace_number.pomp || "").split(/[/\-_]/);
                if (col.length === 3) {
                    setTn1Col(col[0]);
                    setTn2Col(col[1]);
                    setTn3Col(col[2]);
                }
                if (op.length === 3) {
                    setTn1Op(op[0]);
                    setTn2Op(op[1]);
                    setTn3Op(op[2]);
                }
                if (pomp.length === 3) {
                    setTn1Pomp(pomp[0]);
                    setTn2Pomp(pomp[1]);
                    setTn3Pomp(pomp[2]);
                }
            }

            setExisting(true);
        }
    }, [props.system])

    /**
     * TODO: Client_id en installer werken waarschijnlijk niet helemaal. Met name installer niet. 
     */

    const save = () => {
        if (client_id === "" || !("id" in props.installer_data))
            setError(true);
        else {
            var system = {
                name: (typeof props.name !== "undefined" && props.name !== null) ? props.name : "",
                client_id: client_id.includes("FEC_") ? client_id.replace(/\s/g, '') : "FEC_" + client_id.replace(/\s/g, ''),
                installer: props.installer_data.id,
                trace_number: {
                    collector: (tn_1_col !== "" && tn_2_col !== "" && tn_3_col !== "") ? `${tn_1_col}/${tn_2_col}-${tn_3_col}` : "",
                    opslag: (tn_1_op !== "" && tn_2_op !== "" && tn_3_op !== "") ? `${tn_1_op}/${tn_2_op}-${tn_3_op}` : "",
                    pomp: (tn_1_pomp !== "" && tn_2_pomp !== "" && tn_3_pomp !== "") ? `${tn_1_pomp}/${tn_2_pomp}-${tn_3_pomp}` : "",
                }
            };
            if (props.user_id !== null)
                system.id = props.user_id;
            props.save(system);
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={props.open}
            toggle={() => props.close()}
        >
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            {props.text}
                        </div>
                        {props.installers.length > 0 &&
                            <Dropdown options={props.installers.map((inst) => { return { label: inst.name, key: inst.id } })}
                                onChange={(v) => setInstaller(v)}
                                value={installer}
                                color="primary"
                                style={{ width: '100%', marginBottom: 20 }}
                                title="Installateur" />}
                        <FormGroup>
                            <Input
                                id="client_id"
                                placeholder="Apparaat ID"
                                type="text"
                                value={client_id}
                                className={(error && client_id === "") ? "is-invalid" : ""}
                                onChange={(e) => setClientId(e.target.value)}
                            />
                        </FormGroup>
                        <Typography variant="body2" className={`${classes.b2} b2`}>
                            Collector (optioneel)
                        </Typography>
                        <FormGroup className={classes.tn_fields}>
                            <Input
                                placeholder="AAA"
                                type="text"
                                value={tn_1_col}
                                className={classes.tn_1}
                                onChange={(e) => setTn1Col(e.target.value)}
                            />
                            /
                            <Input
                                placeholder="00"
                                type="text"
                                value={tn_2_col}
                                className={classes.tn_2}
                                onChange={(e) => setTn2Col(e.target.value)}
                            />
                            -
                            <Input
                                placeholder="000000"
                                type="text"
                                value={tn_3_col}
                                className={classes.tn_3}
                                onChange={(e) => setTn3Col(e.target.value)}
                            />
                        </FormGroup>
                        <Typography variant="body2" className={`${classes.b2} b2`}>
                            Opslagmodule (optioneel)
                        </Typography>
                        <FormGroup className={classes.tn_fields}>
                            <Input
                                placeholder="AAA"
                                type="text"
                                value={tn_1_op}
                                className={classes.tn_1}
                                onChange={(e) => setTn1Op(e.target.value)}
                            />
                            /
                            <Input
                                placeholder="00"
                                type="text"
                                value={tn_2_op}
                                className={classes.tn_2}
                                onChange={(e) => setTn2Op(e.target.value)}
                            />
                            -
                            <Input
                                placeholder="000000"
                                type="text"
                                value={tn_3_op}
                                className={classes.tn_3}
                                onChange={(e) => setTn3Op(e.target.value)}
                            />
                        </FormGroup>
                        <Typography variant="body2" className={`${classes.b2} b2`}>
                            Pompmodule (optioneel)
                        </Typography>
                        <FormGroup className={classes.tn_fields}>
                            <Input
                                placeholder="AAA"
                                type="text"
                                value={tn_1_pomp}
                                className={classes.tn_1}
                                onChange={(e) => setTn1Pomp(e.target.value)}
                            />
                            /
                            <Input
                                placeholder="00"
                                type="text"
                                value={tn_2_pomp}
                                className={classes.tn_2}
                                onChange={(e) => setTn2Pomp(e.target.value)}
                            />
                            -
                            <Input
                                placeholder="000000"
                                type="text"
                                value={tn_3_pomp}
                                className={classes.tn_3}
                                onChange={(e) => setTn3Pomp(e.target.value)}
                            />
                        </FormGroup>
                        <div className="text-center">
                            <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                style={{ width: 130 }}
                                onClick={() => save()}
                            >
                                Opslaan
                                </Button>
                            <Button
                                className="my-4"
                                type="button"
                                style={{ width: 130 }}
                                onClick={() => props.close()}
                            >
                                Annuleren
                                </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Modal >
    )

}