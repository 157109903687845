import { Typography } from '@material-ui/core';
import React from 'react';
import { Spinner } from 'reactstrap';
import Banner from '../../../assets/components/Banner';
import Footer from '../../../assets/components/Footer';

export default function BoilerLoading(props) {


    return (
        <div className="full-page">
            <Banner />
            <div id="system_info">
                <div>
                    <Spinner />
                    <Typography variant="body1" className="b1" style={{ margin: '10px 0' }}>Gegevens ophalen...</Typography>
                </div>
            </div>
            <Footer />
        </div>
    )
}