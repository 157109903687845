import React from 'react';
import './style/SmallBanner.scss';
import logo from './../img/fectum-logo-white.png';


export default function SmallBanner(props) {

    return (
        <div id="small-banner-main">
            <div id="small-banner-left">
                <img src={logo} alt="" />
                <div id="small-banner-menu-items">
                    {props.user_info.user_type === "admin" &&
                        <span className="menu-item"
                            onClick={() => props.changeTab("unassigned")}>
                            <span className={props.tab === "unassigned" ? "menu-text menu-text-selected" : "menu-text"}>Systemen</span>
                        </span>}
                    <span className="menu-item"
                        onClick={() => props.changeTab("klanten")}>
                        <span className={props.tab === "klanten" ? "menu-text menu-text-selected" : "menu-text"}>Klanten</span>
                    </span>
                    {props.user_info.user_type === "admin" &&
                        <span className="menu-item"
                            onClick={() => props.changeTab("installers")}>
                            <span className={props.tab === "installers" ? "menu-text menu-text-selected" : "menu-text"}>Installateurs</span>
                        </span>}
                    {props.user_info.user_type === "admin" &&
                        <span className="menu-item"
                            onClick={() => props.changeTab("external")}>
                            <span className={props.tab === "external" ? "menu-text menu-text-selected" : "menu-text"}>Extern</span>
                        </span>}
                </div>
            </div>
        </div>
    )
}