import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { Checkbox, makeStyles, Tooltip, Typography } from '@material-ui/core';
import ConfirmationScreen from '../../../../assets/components/ConfirmationScreen';

function filter(data, search, hidden) {
    return data.filter((dp) => {
        if (dp.hide !== hidden)
            return false;
        if (search === "")
            return true;
        if ((dp.client_id + dp.assigned + dp.city + dp.street + dp.number + dp.zip).toLowerCase().includes(search.replace(/\s/g, "").toLowerCase()))
            return true;
        var k = Object.keys(dp.trace_number || {});
        for (var i = 0; i < k.length; i++)
            if (dp.trace_number[k[i]].toLowerCase().includes(search.replace(/\s/g, "").toLowerCase()))
                return true;
    })
}

function descendingComparator(a, b, orderBy, order) {
    if (b[orderBy] === "-")
        return order === "asc" ? 1 : -1;
    if (a[orderBy] === "-")
        return order === "asc" ? -1 : 1;

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, order)
        : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles({
    trace_number: {},
    trace_cell: {
        display: 'flex',
        width: 220,
        justifyContent: 'space-between'
    }
})

export default function UnassignedTable(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('registered_stamp');
    const [confirm, setConfirm] = React.useState({ open: false, client: {}, callback: null })

    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getInstaller = (id) => {
        for (var i = 0; i < props.installers.length; i++) {
            if (props.installers[i].id === id) {
                if (props.installers[i].name.length < 15)
                    return props.installers[i].name;
                return props.installers[i].name.slice(0, 13) + "..."
            }
        }
    }

    const sortClick = (key) => {
        if (key === orderBy && order === "asc") {
            setOrder("desc");
        } else {
            setOrderBy(key);
            setOrder('asc');
        }
    }

    return (
        <Paper className="table-root" variant="outlined">
            <ConfirmationScreen open={confirm.open}
                confirm_button="Ontkoppel"
                close={() => setConfirm({ open: false, client: {}, callback: null })}
                confirm={() => confirm.callback()}>{confirm.text || ""}</ConfirmationScreen>
            <TableContainer className="table-container">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "registered_stamp"}
                                    direction={orderBy === "registered_stamp" ? order : 'asc'}
                                    onClick={() => sortClick("registered_stamp")}>
                                    Geregistreerd
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                Apparaat ID
                            </TableCell>
                            <TableCell className="table-cell">
                                Installateur
                            </TableCell>
                            <TableCell className="table-cell">
                                Extern
                            </TableCell>
                            <TableCell className={`table-cell ${classes.trace_number}`}>
                                Traceernummers
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "city"}
                                    direction={orderBy === "city" ? order : 'asc'}
                                    onClick={() => sortClick("city")}>
                                    Plaats
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                Handmatig gekoppeld
                            </TableCell>
                            <TableCell className="table-cell">
                                Laatst geupdated
                            </TableCell>
                            <TableCell style={{ width: 190, fontWeight: 'bold' }} align="center">
                                Acties
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filter(stableSort(props.data, getComparator(order, orderBy)), props.search, props.hidden).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                    <TableCell>
                                        {new Date(row.registered_stamp * 1000).toLocaleDateString("en-UK")}
                                    </TableCell>
                                    <TableCell>
                                        {row.name === "" ? row.client_id :
                                            <div>
                                                <div>{row.name}</div>
                                                <div>{row.client_id}</div>
                                            </div>}
                                    </TableCell>
                                    <TableCell>
                                        {(props.installers_dict[row.installer] || { name: "Niet bekend" }).name}
                                    </TableCell>
                                    <TableCell>
                                        <div>{(row.manual_external || "") !== "" ? props.external_dict[row.manual_external].name : "-"}</div>
                                        <div>{(row.manual_external || "") !== "" ? props.external_dict[row.manual_external].rol : "-"}</div>
                                    </TableCell>
                                    <TableCell className={classes.trace_number}>
                                        <div className={classes.trace_cell}><span>Collector:</span><span>{row.trace_number.collector || "-"}</span></div>
                                        <div className={classes.trace_cell}><span>Opslag:</span><span>{row.trace_number.opslag || "-"}</span></div>
                                        <div className={classes.trace_cell}><span>Pomp:</span><span>{row.trace_number.pomp || "-"}</span></div>
                                    </TableCell>
                                    <TableCell>
                                        {row.city || ""}
                                    </TableCell>
                                    <TableCell>
                                        {row.manual_installer ? "Ja" : "Nee"}
                                    </TableCell>
                                    <TableCell>
                                        {row.last_updated !== -1 ? new Date(row.last_updated * 1000).toLocaleDateString("en-UK") : "-"}
                                    </TableCell>
                                    <TableCell className="table-actions">
                                        <Button size="sm"
                                            id={`view-btn-${(i + page * rowsPerPage)}`}
                                            onClick={() => window.open(("/systeem-status#" + row.client_id), "_blank")}
                                            color="info">
                                            <i className="fa fa-eye"></i>
                                        </Button>
                                        <Button size="sm"
                                            id={`chart-btn-${(i + page * rowsPerPage)}`}
                                            color="default"
                                            onClick={() => props.chartData(row.client_id, row)}>
                                            <i className="ni ni-chart-bar-32"></i>
                                        </Button>
                                        <Button size="sm"
                                            id={`chart-btn-${(i + page * rowsPerPage)}`}
                                            color="primary"
                                            onClick={() => props.toggleHide(row.client_id, !(row.hide || false))}>
                                            <i className="fa fa-eye-slash"></i>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 75, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
