import { useEffect, useState } from "react";
import { fetchPost } from "../../../../toolbox/fetchlib";
import { failedNotification } from "../../../../toolbox/notification-center";

const postNewInstaller = "/api/add_installer";
const postDelete = "/api/delete_users";
const edit_installer = "/api/edit_installer";

export const InstallerData = () => {

    const newInstaller = (args, callback, error_callback) => {
        fetchPost(postNewInstaller, args, (data) => {
            callback();
        }, (er) => {
            error_callback(er.message);
        })
    }

    const deleteInstallers = (targets, callback) => {
        fetchPost(postDelete, {ids: targets}, (data) => {
            callback();
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan installateur niet verwijderen. Probeer het later opnieuw.")
        })
    }

    const editInstaller = (creds, callback) => {
        fetchPost(edit_installer, creds, (data) => {
            callback();
        }, (er) => {
            if (er.status === 450 )
                failedNotification("Niet gelukt.", "Wachtwoord moet minimaal 8 karakters bevatten waaronder een hoofdletter, speciaal teken en cijfer.")
            else
                failedNotification("Niet gelukt.", "Kan installateur niet aanpassen. Probeer het later opnieuw.")
        })
    }

    return { 
        newInstaller,
        deleteInstallers,
        editInstaller
    };

}