import React, { useState } from 'react';
import ConfirmationScreen from '../../../../assets/components/ConfirmationScreen';
import TableToolbar from '../TableToolbar';
import { ExternalData } from './ExternalData';
import ExternalModal from './ExternalModal';
import ExternalTable from './ExternalTable';

export default function ExternalOverview(props) {

    const { newExternal, deleteExternal, editExternal } = ExternalData(props);

    const default_modal = {open: false, callback: null, external: null};

    const [search, setSearch] = useState("");
    const [modal, setModal] = useState(default_modal);
    const [confirm_modal, setConfirmModal] = useState(false);
    const [selected, setSelected] = useState([]);

    const selectExternal = (arg) => {
        if (arg === "all")
            setSelected(props.externals.map((inst) => inst.id));
        else if (arg === "none")
            setSelected([]);
        else {
            var index = selected.indexOf(arg), new_selected = [...selected];
            index >= 0 ? new_selected.splice(index, 1) : new_selected.push(arg);
            setSelected(new_selected);
        }
    }

    return (
        <div className="tab-overview">
            <TableToolbar changeSearch={(v) => setSearch(v)}
                onButtonClick={() => setModal({open: true, callback: newExternal.bind(this), external: null})}
                buttontext="Nieuw"
                onSecondButtonClick={() => setConfirmModal(true)}
                second_button_disabled={!selected.length}
                secondbuttontext="Verwijderen" />
            <ExternalTable data={props.external}
                search={search}
                selected={selected}
                editExternal={(inst) => setModal({open: true, callback: editExternal.bind(this), external: inst})}
                selectAll={() => selectExternal("all")}
                deselectAll={() => selectExternal("none")}
                editClient={(id) => selectExternal(id)} />
            {modal.open && 
            <ExternalModal open={modal.open}
                close={() => setModal(default_modal)}
                external={modal.external}
                fetchExternal={props.fetchExternal.bind(this)}
                textAlign="left"
                confirm_button="Opslaan"
                saveExternal={(data, cb, ecb) => modal.callback(data, cb, ecb)} />}
            <ConfirmationScreen open={confirm_modal}
                close={() => setConfirmModal(false)}
                textAlign="left"
                confirm_button="Bevestig"
                confirm={() => deleteExternal(selected, () => {
                    props.fetchExternal();
                }) & setConfirmModal(false)}>
                Weet je zeker dat je deze installateurs wil verwijderen?
            </ConfirmationScreen>
        </div>
    )

}