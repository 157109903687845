import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export const successNotification = (title, text) => {
    store.addNotification({
        title: title,
        message: text,
        type: "success",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            click: false,
            showIcon: true,
            duration: 5000
        }
    });
}

export const infoNotification = (title, text) => {
    store.addNotification({
        title: title,
        message: text,
        type: "info",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            click: false,
            showIcon: true,
            duration: 5000
        }
    });
}

export const failedNotification = (title, text) => {
    store.addNotification({
        title: title,
        message: text,
        type: "danger",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            click: false,
            showIcon: true,
            duration: 5000
        }
    });
}