import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Container, Row } from 'reactstrap';
import SmallBanner from '../../assets/components/SmallBanner';
import { set_origin } from "../../redux/actions";
import { fetchGet } from '../../toolbox/fetchlib';
import ClientOverview from './components/clients_overview/ClientOverview';
import UnassignedOverview from './components/unassigned_overview/UnassignedOverview';
import InstallerOverview from './components/installer_overview/InstallerOverview';

import './style/Admin.scss';
import DataOverview from './components/data_overview/DataOverview';
import AdminData from './AdminData';
import ExternalOverview from './components/external_overview/ExternalOverview';
import SystemLinker from './components/unassigned_overview/SystemLinker';
import ConfirmationScreen from '../../assets/components/ConfirmationScreen';
import ExternalLinker from './components/unassigned_overview/ExternalLinker';
import ClientModal from './components/clients_overview/ClientModal';
import SystemEditor from './components/SystemEditor';

const desiredTopics = ["temp01", "temp02", "temp03", "temp04", "pump01pwm"];
const defaultSystemLink = { open: false, client_id: "" };
const defaultExternalLink = { open: false, client_id: "" };
const defaultConfirmation = { open: false, callback: null, text: "" };
const defaultSystemEditor = { open: false, system: null, name: "", user_id: "" };

export default function Admin(props) {

    const {
        system_data,
        installers,
        external,
        installers_dict,
        external_dict,
        topics,
        cust_data,
        loading,
        reset_table,
        fetchData,
        unlink,
        unlinkManual,
        unlinkManualExternal,
        fetchInstallers,
        fetchExternal,
        fetchTopics,
        updateClient,
        deleteUser,
        saveSystem,
        savePw,
        toggleHide,
        saveSystemLink,
        saveExternalLink,
        storeNote
    } = AdminData();

    const user_info = useSelector(state => state.user_info);
    const history = useHistory();
    const dispatch = useDispatch();

    const [tab, setTab] = useState('unassigned');
    const [prev_tab, setPrevTab] = useState('');
    const [id, setID] = useState("");
    const [chart_data, setChartData] = useState({});
    const [system_link, setSystemLink] = useState(defaultSystemLink);
    const [external_link, setExternalLink] = useState(defaultExternalLink);
    const [confirmation, setConfirmation] = useState(defaultConfirmation);
    const [system_editor, setSystemEditor] = useState(defaultSystemEditor);
    const [client_edit, setClientEdit] = useState({ open: false, client: {} });

    useEffect(() => {
        if (!user_info.initialized) {
            dispatch(set_origin('admin'));
            history.push('/');
        } else {
            fetchInstallers();
            fetchExternal();
            fetchTopics();
        }
    }, []);

    const findChartData = (id, info) => {
        setID(id);
        system_data.map((system) => {
            if (system.client_id === id)
                setChartData(system);
        })
        setTab("data");
        setPrevTab("klanten");
    }

    const getSystem = () => {
        for (var i=0; i<system_data.length; i++)
            if (system_data[i].client_id === id)
                return system_data[i];
        return {};
    }

    return (
        <div id="admin-container">
            {confirmation.open &&
                <ConfirmationScreen open={confirmation.open}
                    close={() => setConfirmation(defaultConfirmation)}
                    textAlign="left"
                    confirm_button="Bevestig"
                    confirm={() => confirmation.callback()}>
                    {confirmation.text}
                </ConfirmationScreen>
            }
            {system_link.open &&
                <SystemLinker installers={installers}
                    open={system_link.open}
                    close={() => setSystemLink(defaultSystemLink)}
                    save={(args) => saveSystemLink(args, () => setSystemLink(defaultSystemLink))}
                    client_id={system_link.client_id} />}
            {external_link.open &&
                <ExternalLinker external={external}
                    open={external_link.open}
                    close={() => setExternalLink(defaultExternalLink)}
                    save={(args) => saveExternalLink(args, () => setExternalLink(defaultExternalLink))}
                    client_id={external_link.client_id} />}
            {client_edit.open &&
                <ClientModal open={client_edit.open}
                    close={() => setClientEdit({ open: false, client: {} })}
                    installers={installers}
                    text="Wijzig klant gegevens"
                    client={client_edit.client}
                    savePw={savePw.bind(this)}
                    saveClient={updateClient.bind(this)} />}
            {system_editor.open &&
                <SystemEditor open={system_editor.open}
                    installers={installers}
                    system={system_editor.system}
                    user_id={system_editor.user_id}
                    name={system_editor.name}
                    lock_id={true}
                    close={() => setSystemEditor(defaultSystemEditor)}
                    save={(v) => saveSystem(v, () => setSystemEditor(defaultSystemEditor))}/>}
            <SmallBanner tab={tab} changeTab={(val) => setTab(val)} user_info={user_info} />
            {tab === "klanten" &&
                <ClientOverview installers={installers}
                    data={cust_data}
                    reset_table={false}
                    deleteUser={deleteUser.bind(this)}
                    saveSystem={saveSystem.bind(this)}
                    savePw={savePw.bind(this)}
                    updateClient={updateClient.bind(this)}
                    chartData={(id, info) => findChartData(id, info)} />}
            {tab === "unassigned" &&
                <UnassignedOverview data={system_data}
                    loading={loading}
                    installers={installers}
                    external={external}
                    installers_dict={installers_dict}
                    external_dict={external_dict}
                    saveExternalLink={saveExternalLink.bind(this)}
                    saveSystemLink={saveSystemLink.bind(this)}
                    toggleHide={toggleHide.bind(this)}
                    unlink={unlink.bind(this)}
                    unlinkManual={unlinkManual.bind(this)}
                    unlinkManualExternal={unlinkManualExternal.bind(this)}
                    chartData={(id, info) => setID(id) & setTab("data") & setPrevTab("unassigned") & setChartData(info)} />}
            {tab === "installers" &&
                <InstallerOverview installers={installers} fetchInstallers={fetchInstallers.bind(this)} />}
            {tab === "external" &&
                <ExternalOverview external={external} fetchExternal={fetchExternal.bind(this)} />}
            {tab === "data" &&
                <DataOverview topics={topics}
                    linkInstaller={(id) => setSystemLink({ open: true, client_id: id })}
                    linkExternal={(id) => setExternalLink({ open: true, client_id: id })}
                    client_id={id}
                    cust_data={cust_data}
                    editClient={(client) => { setClientEdit({ open: true, client: client }) }}
                    editSystem={(system, name, uid) => setSystemEditor({open: true, user_id: uid, system: system, name: name})}
                    setConfirmation={setConfirmation.bind(this)}
                    unlink={unlink.bind(this)}
                    unlinkManual={unlinkManual.bind(this)}
                    unlinkManualExternal={unlinkManualExternal.bind(this)}
                    storeNote={storeNote.bind(this)}
                    data_info={getSystem()}
                    desiredTopics={desiredTopics}
                    external_dict={external_dict}
                    installers_dict={installers_dict}
                    navBack={() => setTab(prev_tab) & setID("") & setChartData({})} />}
        </div>
    )
}