import React, { useState } from 'react';
import ConfirmationScreen from '../../../../assets/components/ConfirmationScreen';
import SystemEditor from '../SystemEditor';
import TableToolbar from '../TableToolbar';
import ClientModal from './ClientModal';
import ClientsTable from './ClientsTable';

export default function ClientOverview(props) {

    const default_editor = {open: false, system: null, text: "", callback: null, name: "", user_id: null};
    const default_confirm = {open: false, text: "", callback: null};

    const [modal, setModal] = useState({open: false, client: {}});
    const [search, setSearch] = useState("");
    const [editor, setEditor] = useState(default_editor)
    const [confirm, setConfirm] = useState(default_confirm)

    return (
        <div className="tab-overview">
            <TableToolbar changeSearch={(v) => setSearch(v)}/>
            <ClientsTable data={props.data}
                search={search}
                installers={props.installers}
                reset_table={props.reset_table}
                deleteUser={props.deleteUser.bind(this)}
                deleteUser={(id) => setConfirm({
                    open: true,
                    text: "Weet je zeker dat je deze gebruiker wilt verwijderen?",
                    callback: () => props.deleteUser(id) & setConfirm(default_confirm)
                })}
                chartData={props.chartData.bind(this)}
                editSystem={(s, name, id) => setEditor({
                    open: true,
                    user_id: id,
                    system: s,
                    name: name,
                    text: s === null ? "Nieuw systeem koppelen" : "Systeem wijzigen",
                    callback: (s) => props.saveSystem(s, () => setEditor(default_editor))
                })}
                editClient={(c) => setModal({open: true, client: c})} />
            <ClientModal open={modal.open}
                close={() => setModal({open: false, client: {}})}
                installers={props.installers}
                text="Wijzig klant gegevens"
                client={modal.client}
                savePw={props.savePw.bind(this)}
                saveClient={props.updateClient.bind(this)}/>
            {editor.open && 
            <SystemEditor open={editor.open}
                installers={props.installers}
                system={editor.system}
                user_id={editor.user_id}
                name={editor.name}
                close={() => setEditor(default_editor)}
                text={editor.text}
                save={(v) => editor.callback(v)}/>}
            {confirm.open && 
            <ConfirmationScreen confirm={() => confirm.callback()}
                open={confirm.open}
                close={() => setConfirm(default_confirm)}>{confirm.text}</ConfirmationScreen>}
        </div>
    )

}