import { makeStyles } from '@material-ui/core';
import React from 'react';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap";
import './style/CustomItems.scss';


const dropDownStyle = makeStyles({
    root: {
        //  width: 170
    },
    solesta: {
        "&button": {
            background: "linear-gradient(to right, #f9cb0a, #f18e1f)"
        }
    }
})

export function Dropdown(props) {

    const classes = dropDownStyle();

    return (
        <UncontrolledDropdown group className={props.color === "solesta" ? "solesta-dropdown" : ""}
            color={props.color === "solesta" ? "" : props.color}
            style={props.style}>
            <DropdownToggle caret color="secondary">
                {(props.value === "" || isNaN(props.value) || props.value<0) ? props.title : props.options[props.value*1].label}
            </DropdownToggle>
            <DropdownMenu>
                {props.options.map((option, i) =>
                    <DropdownItem onClick={e => props.onChange(i)} key={i}>
                        {option.label}
                    </DropdownItem>
                )}
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}