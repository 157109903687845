import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/scss/argon-design-system-react.scss";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./App.css";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Main from "./Main";
import Login from "./pages/login/Login";
import NewAccount from "./pages/login/NewAccount";
import SystemInfo from "./pages/system_info/SystemInfo";
import Admin from "./pages/admin/Admin";
import Installer from "./pages/installer/Installer";
import VerifyAccount from "./pages/login/VerifyAccount";
import Logout from "./pages/login/Logout";
import ReactNotification from 'react-notifications-component'
import ForgotPassword from "./pages/login/ForgotPassword";
import ResetPassword from "./pages/login/ResetPassword";


function App() {

  return (
    <BrowserRouter>
      <ReactNotification />
      <Switch>
        <Route path="/" component={Main} exact />
        <Route path="/login" component={Login} />
        <Route path="/wachtwoord-vergeten" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/nieuw-account" component={NewAccount} exact />
        <Route path="/verify_account" component={VerifyAccount} exact />
        <Route path="/systeem-status" component={SystemInfo} exact />
        <Route path="/admin" component={Admin} exact />
        <Route path="/installateur" component={Installer} exact />
        <Route path="/logout" component={Logout} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
