import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { fetchPost } from '../../toolbox/fetchlib';

const logout = "/api/logout";

export default function Logout() {

    const history = useHistory();

    useEffect(() => {
        fetchPost(logout, {}, (data) => {
            history.push('login');
        }, (er) => {});
    }, [])


    return (<div></div>)
}