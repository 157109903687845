import React, { useEffect, useState } from 'react';
import { Divider, Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { ChartData } from './ChartData';
import { LineChart } from './../../../../assets/components/LineChart';
import { Button, Spinner } from 'reactstrap';
import { Toolbar } from './Toolbar';
import ConfirmationScreen from '../../../../assets/components/ConfirmationScreen';
import { fetchPost } from '../../../../toolbox/fetchlib';
import { failedNotification } from '../../../../toolbox/notification-center';

const get_notes = "/api/get_notes";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    content: {
        padding: 15,
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#f5f7f9'
    },
    paper_styles: {
        padding: 15
    },
    paper_title: {
        padding: '5px 0 15px',
        fontWeight: 'bold'
    },
    divider: {
        marginBottom: 15,
        backgroundColor: 'rgba(0,0,0,.1)'
    },
    note_field: {
        width: '80%'
    },
    note_btns: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    paper_header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    paper_content: {
        width: "99%",
        maxWidth: 700,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 100,
    },
    central_message: {
        width: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 100,
    },
    section_content: {
        display: 'flex',
        width: 250,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    subject_header: {
        fontSize: 14,
        fontWeight: 'bold',
        display: 'inline'
    },
    subject_value: {
        fontSize: 14,
        display: 'inline'
    },
    btn: {
        padding: '5px 10px',
        height: 28,
        marginTop: -12
    }
})

export default function DataOverview(props) {

    const classes = useStyles();

    const { full_data, loading, dates, setDates, updateData } = ChartData(props.topics, props.client_id);
    
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Toolbar dates={dates}
                    navBack={props.navBack.bind(this)}
                    setDates={(start, end) => setDates({ start: start, end: end })}
                    client_id={props.client_id}
                    data_info={props.data_info}
                    updateData={updateData.bind(this)} />
                {(typeof full_data !== 'undefined' && !loading) &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Systeem {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Klant {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Extern {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Installateur {...props} />
                        </Grid>{/*
                        <Grid item xs={12} sm={6}>
                            <Notities {...props} />
                        </Grid>*/}
                        <Grid item xs={12} sm={12}>
                            <LineChart title="Temperatuur Overzicht" title_5="Vat boven" title_2="Vat onder" title_3="Collector"
                                title_1="Pomp %"
                                title_4="Extern"
                                data={full_data["pump01pwm"]}
                                data_5={full_data["temp02"]}
                                data_2={full_data["temp03"]}
                                data_3={full_data["temp01"]}
                                data_4={full_data["temp04"]}
                                loading={loading} />
                        </Grid>
                    </Grid>
                }
                {(typeof full_data === 'undefined' || loading) &&
                    <Spinner />
                }
            </div>

        </div>
    )

}

function Systeem(props) {
    const classes = useStyles();

    

    return (
        <Paper className={classes.paper_styles}>
            <div className={classes.paper_header}>
                <Typography variant="h6" className={classes.paper_title}>Systeem</Typography>
                <Button size="sm" onClick={() => props.editSystem({client_id: props.data_info.client_id, installer: props.data_info.installer, trace_number: props.data_info.trace_number}, `${props.data_info.zip} - ${props.data_info.number}`, props.data_info.user_id)}
                    disabled={!("user_id" in props.data_info) || props.data_info.user_id === ""}
                    className={classes.btn} color="primary">Wijzigen</Button>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.paper_content}>
                <div className={classes.paper_content_section}>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>ID</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.client_id}</Typography>
                    </div>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Naam</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.system_name}</Typography>
                    </div>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Laatst geupdated</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.last_updated !== -1 ? new Date(props.data_info.last_updated * 1000).toLocaleDateString("en-UK") : "-"}</Typography>
                    </div>
                </div>
                <div className={classes.paper_content_section}>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Collector</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.trace_number.collector || "-"}</Typography>
                    </div>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Opslag</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.trace_number.opslag || "-"}</Typography>
                    </div>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Pomp</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.trace_number.pomp || "-"}</Typography>
                    </div>
                </div>
            </div>
        </Paper>
    )
}

function Waarschuwingen(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper_styles}>
            <Typography variant="h6" className={classes.paper_title}>Waarschuwingen</Typography>
            <Divider className={classes.divider} />
            <div className={classes.paper_content}>
                Coming soon..
            </div>
        </Paper>
    )
}

function Notities(props) {
    const classes = useStyles();

    const [note_set, setNoteSet] = useState(false);
    const [notes_value, setNotesValue] = useState("");
    const [del_window, setDelWindow] = useState(false);

    useEffect(() => {
        fetchPost(get_notes, { client_ids: [props.client_id] }, (data) => {
            if (props.client_id in data)
                setNotesValue(data[props.client_id]);
        }, (er) => {
            failedNotification("Ophalen mislukt.", "Kan notities nu niet ophalen.");
        })
    }, [])

    const saveNotes = () => {
        props.storeNote({ client_id: props.client_id, note: notes_value }, "opgeslagen");
    }

    const delNotes = () => {
        props.storeNote({ client_id: props.client_id, note: "" }, "verwijderd", () => {
            setNotesValue("");
            setDelWindow(false);
        });
    }

    return (
        <Paper className={classes.paper_styles}>
            {del_window &&
                <ConfirmationScreen confirm={() => delNotes()}
                    open={del_window}
                    close={() => setDelWindow(false)}>Weet je zeker dat je de notities wilt verwijderen?</ConfirmationScreen>}
            <Typography variant="h6" className={classes.paper_title}>Notities</Typography>
            <Divider className={classes.divider} />
            <div className={classes.paper_content} style={{ maxWidth: 'none' }}>
                <TextField
                    className={classes.note_field}
                    id="outlined-multiline-static"
                    variant="outlined"
                    multiline
                    value={notes_value}
                    onChange={(e) => setNotesValue(e.target.value)}
                    rows={3}
                    placeholder="Notities"
                />
                <div className={classes.note_btns}>
                    <Button color="primary" size="sm" onClick={() => saveNotes()}>
                        Opslaan
                    </Button>
                    <Button size="sm" onClick={() => setDelWindow(true)}>
                        Verwijderen
                    </Button>
                </div>
            </div>
        </Paper>
    )
}

function Klant(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper_styles}>
            <div className={classes.paper_header}>
                <Typography variant="h6" className={classes.paper_title}>Klant</Typography>
                <Button size="sm" className={classes.btn} onClick={() => props.editClient(props.data_info)} color="primary">
                    {("user_id" in props.data_info && props.data_info.user_id !== "") ? "Klant aanpassen" : "Klant linken"}
                </Button>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.paper_content}>
                <div className={classes.paper_content_section}>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Naam</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.name === "1234JA" ? `${props.data_info.zip} - ${props.data_info.number}` : props.data_info.name}</Typography>
                    </div>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Email</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.email}</Typography>
                    </div>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Telefoon</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.phone_number}</Typography>
                    </div>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Contact</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.allow_contact ? "Akkoord" : "Niet akkoord"}</Typography>
                    </div>
                </div>
                <div className={classes.paper_content_section}>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Adres</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.address || "-"}</Typography>
                    </div>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Postcode</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.zip || "-"}</Typography>
                    </div>
                    <div className={classes.section_content}>
                        <Typography variant="body2" className={classes.subject_header}>Plaats</Typography>
                        <Typography variant="body2" className={classes.subject_value}>{props.data_info.city || "-"}</Typography>
                    </div>
                </div>
            </div>
        </Paper>
    )
}

function Installateur(props) {

    const classes = useStyles();

    return (
        <Paper className={classes.paper_styles}>
            <div className={classes.paper_header}>
                <Typography variant="h6" className={classes.paper_title}>Installateur</Typography>
                {!("installer" in props.data_info && props.data_info.installer in props.installers_dict) &&
                    <Button size="sm" color="primary" className={classes.btn} onClick={() => props.linkInstaller(props.client_id)} style={{ marginTop: -12 }}>
                        Nieuwe koppelen
                    </Button>
                }
                {("installer" in props.data_info && props.data_info.installer in props.installers_dict) &&
                    <Button size="sm" color="warning" className={classes.btn} style={{ marginTop: -12 }}
                        onClick={() => props.data_info.manual_installer ? props.setConfirmation({ open: true, text: "Weet je zeker dat je dit systeem wil ontkoppelen?", callback: () => props.unlinkManual(props.data_info, () => props.setConfirmation({ open: false, callback: null })) }) :
                            props.setConfirmation({ open: true, text: "Weet je zeker dat je dit systeem wil ontkoppelen?", callback: () => props.unlink(props.data_info) & props.setConfirmation({ open: false, client: {} }) })}>
                        Koppeling verwijderen
                    </Button>
                }
            </div>
            <Divider className={classes.divider} />
            {("installer" in props.data_info && props.data_info.installer in props.installers_dict) &&
                <div className={classes.paper_content}>
                    <div className={classes.paper_content_section}>
                        <div className={classes.section_content}>
                            <Typography variant="body2" className={classes.subject_header}>Naam</Typography>
                            <Typography variant="body2" className={classes.subject_value}>{props.installers_dict[props.data_info.installer].name}</Typography>
                        </div>
                        <div className={classes.section_content}>
                            <Typography variant="body2" className={classes.subject_header}>Contact</Typography>
                            <Typography variant="body2" className={classes.subject_value}>{props.installers_dict[props.data_info.installer].contact_person}</Typography>
                        </div>
                    </div>
                    <div className={classes.paper_content_section}>
                        <div className={classes.section_content}>
                            <Typography variant="body2" className={classes.subject_header}>Telefoon</Typography>
                            <Typography variant="body2" className={classes.subject_value}>{props.installers_dict[props.data_info.installer].phone_number}</Typography>
                        </div>
                        <div className={classes.section_content}>
                            <Typography variant="body2" className={classes.subject_header}>Email</Typography>
                            <Typography variant="body2" className={classes.subject_value}>{props.installers_dict[props.data_info.installer].email}</Typography>
                        </div>
                    </div>
                </div>}
            {!("installer" in props.data_info && props.data_info.installer in props.installers_dict) &&
                <div className={classes.central_message}>
                    Geen installateur gekoppeld
                </div>
            }
        </Paper>
    );

}

function Extern(props) {

    const classes = useStyles();

    return (
        <Paper className={classes.paper_styles}>
            <div className={classes.paper_header}>
                <Typography variant="h6" className={classes.paper_title}>Extern</Typography>
                {!("manual_external" in props.data_info && props.data_info.manual_external in props.external_dict) &&
                    <Button size="sm" color="primary" className={classes.btn} onClick={() => props.linkExternal(props.client_id)} style={{ marginTop: -12 }}>
                        Nieuwe koppelen
                    </Button>
                }
                {("manual_external" in props.data_info && props.data_info.manual_external in props.external_dict) &&
                    <Button size="sm" color="warning" className={classes.btn} style={{ marginTop: -12 }}
                        onClick={() => props.setConfirmation({ open: true, text: "Weet je zeker dat je dit systeem wil ontkoppelen?", callback: () => props.unlinkManualExternal(props.data_info, () => props.setConfirmation({ open: false, callback: null })) })}>
                        Koppeling verwijderen
                    </Button>
                }
            </div>
            <Divider className={classes.divider} />
            {("manual_external" in props.data_info && props.data_info.manual_external in props.external_dict) &&
                <div className={classes.paper_content}>
                    <div className={classes.paper_content_section}>
                        <div className={classes.section_content}>
                            <Typography variant="body2" className={classes.subject_header}>Naam</Typography>
                            <Typography variant="body2" className={classes.subject_value}>{props.external_dict[props.data_info.manual_external].name}</Typography>
                        </div>
                        <div className={classes.section_content}>
                            <Typography variant="body2" className={classes.subject_header}>Contact</Typography>
                            <Typography variant="body2" className={classes.subject_value}>{props.external_dict[props.data_info.manual_external].contact_person}</Typography>
                        </div>
                    </div>
                    <div className={classes.paper_content_section}>
                        <div className={classes.section_content}>
                            <Typography variant="body2" className={classes.subject_header}>Telefoon</Typography>
                            <Typography variant="body2" className={classes.subject_value}>{props.external_dict[props.data_info.manual_external].phone_number}</Typography>
                        </div>
                        <div className={classes.section_content}>
                            <Typography variant="body2" className={classes.subject_header}>Email</Typography>
                            <Typography variant="body2" className={classes.subject_value}>{props.external_dict[props.data_info.manual_external].email}</Typography>
                        </div>
                    </div>
                </div>}
            {!("manual_external" in props.data_info && props.data_info.manual_external in props.external_dict) &&
                <div className={classes.central_message}>
                    Geen extern gekoppeld
                </div>
            }
        </Paper>
    );

}
