import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import classnames from "classnames";

import Banner from '../../../assets/components/Banner';
import Footer from '../../../assets/components/Footer';
import {
    Card,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Button,
    FormGroup,
    Form,
    Input,
    InputGroup,
    Spinner,
} from "reactstrap";
import { fetchGet, fetchPost } from '../../../toolbox/fetchlib';
import { useSelector } from 'react-redux';
import { Dropdown } from '../../../assets/components/CustomItems';
import { useHistory } from 'react-router';
import SystemEditor from '../../admin/components/SystemEditor';
import { failedNotification, successNotification } from '../../../toolbox/notification-center';
import { jsonEmpty } from '../../../toolbox/utils';

const getSystemInfo = "/api/get_system_info"
const infoCall = "/api/post_user_info";
const get_installers = "/api/get_installers";
const post_creds = "/api/connect_smartline";
const logout_call = "/api/logout"
const reset_password = "/api/change_password"

const useStyles = makeStyles({
    creds: {
        margin: '0 auto',
        width: '98%',
        maxWidth: 500
    },
    creds_fields: {
        width: '100%',
        maxWidth: 280,
        margin: '30px auto 0',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        maxWidth: 280,
        margin: '0 auto'
    },
    tn_fields: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tn_1: {
        width: 65
    },
    tn_2: {
        width: 60
    },
    tn_3: {
        width: 75
    }
})

export default function InfoEditor(props) {

    const customer_info = useSelector(state => state.customer_info);

    const [info, setInfo] = useState({});
    const [info_set, setInfoSet] = useState(false);
    const [installers, setInstallers] = useState([]);

    useEffect(() => {
        fetchGet(get_installers, (data) => {
            setInstallers(data.installers);
        }, (er) => { })
    }, []);

    useEffect(() => {
        if (!info_set) {
            setInfo({
                name: customer_info.name || "",
                phone_number: customer_info.phone_number || "",
                street: customer_info.street || "",
                number: customer_info.number || "",
                zip: customer_info.zip || "",
                city: customer_info.city || ""
            })
            setInfoSet(true);
        }
    }, [customer_info])

    return (
        <div className="full-page">
            <Banner />
            <div id="system_info">
                <Systems client_ids={customer_info.client_ids || []}
                    name={`${customer_info.zip} - ${customer_info.number}`}
                    installers={installers} fetchAccInfo={props.fetchAccInfo.bind(this)} />
                <Password />
                <Info client={info} fetchAccInfo={props.fetchAccInfo.bind(this)} />
            </div>
            <Footer changeUserInfo={props.changeUserInfo.bind(this)} back />
        </div>
    )

}

function Systems(props) {

    const classes = useStyles();

    const [selected, setSelected] = useState(0);
    const default_editor = { open: false, system: null, text: "", callback: null, name: "" };
    const [editor, setEditor] = useState(default_editor)

    const saveSystem = (system) => {
        fetchPost(post_creds, system, (data) => {
            if (data.success) {
                setEditor(default_editor);
                props.fetchAccInfo(true);
            }
            else if (data.status === 1)
                failedNotification("Apparaat ID niet gevonden.", "Apparaat ID bestaat niet. Controleer of dit veld juist is ingevuld.")
            else if (data.status === 2)
                failedNotification("Apparaat ID al gekoppeld.", "Apparaat ID al gekoppeld aan een ander apparaat. Neem contact op met Solesta.")
            else
                failedNotification("Koppelen mislukt.", "Probeer het later opnieuw.");
        }, (er) => { failedNotification("Koppelen mislukt.", "Probeer het later opnieuw."); })
    }

    const openEditor = (s) => {
        setEditor({
            open: true,
            system: s,
            name: props.name,
            text: s === null ? "Nieuw systeem koppelen" : "Systeem wijzigen",
            callback: (s) => saveSystem(s)
        })
    }

    return (
        <div>
            {editor.open &&
                <SystemEditor open={editor.open}
                    installers={props.installers}
                    system={editor.system}
                    name={editor.name}
                    close={() => setEditor(default_editor)}
                    text={editor.text}
                    save={(v) => editor.callback(v)} />}
            <Typography variant="body2" className="b2" style={{ marginBottom: 15 }}>
                Mijn systemen:
            </Typography>
            {props.client_ids.length === 0 ?
                <Typography variant="body2" className="b2">
                    Geen systemen gekoppeld.
                </Typography> :
                <Dropdown options={props.client_ids.map((clid) => { return { label: clid.name, key: clid.client_id } })}
                    onChange={(v) => setSelected(v)}
                    value={selected}
                    style={{ width: 280 }}
                    color="primary"
                    title="Systemen" />}
            <div className={classes.buttons}>
                <Button
                    className="my-4"
                    color="warning"
                    type="button"
                    style={{ width: '100%' }}
                    onClick={() => openEditor(props.client_ids[selected])}
                >
                    Wijzigen
                </Button>
                <Button
                    className="my-4"
                    type="button"
                    style={{ width: '100%' }}
                    onClick={() => openEditor(null)}
                >
                    Nieuw
                </Button>
            </div>
        </div>
    )

}

function Password() {
    const classes = useStyles();

    const [old_pw, setOldPw] = useState("");
    const [new_pw1, setNewPw1] = useState("");
    const [new_pw2, setNewPw2] = useState("");

    const savePw = () => {
        if (new_pw1 === new_pw2 && new_pw1 !== "" && old_pw !== "") {
            fetchPost(reset_password, {
                old_password: old_pw,
                new_password: new_pw1
            }, (data) => {
                successNotification("Gelukt", "Wachtwoord gewijzigd.")
            }, (er) => {
                if (er.status === 450)
                    failedNotification("Niet gelukt", "Wachtwoord moet minimaal 7 tekens bevatten waaronder een hoofdletter, cijfer en speciaal teken.")
                else
                    failedNotification("Niet gelukt", "Probeer later opniew.")
            })
        } else
            failedNotification("Niet gelukt", "Wachtwoorden komen niet overeen.")
    }


    return (
        <div className={classes.creds_fields}>
            <Typography variant="body2" className="b2" style={{ margin: '15px 0' }}>
                Mijn wachtwoord:
            </Typography>
            <Form role="form">
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input placeholder={"Huidig wachtwoord"}
                            value={old_pw}
                            type="password"
                            onChange={(e) => setOldPw(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input placeholder={"Nieuw wachtwoord"}
                            value={new_pw1}
                            type="password"
                            onChange={(e) => setNewPw1(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input placeholder={"Herhaal wachtwoord"}
                            value={new_pw2}
                            type="password"
                            onChange={(e) => setNewPw2(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <div className={classes.buttons}>
                    <Button
                        className="my-4"
                        color="warning"
                        type="button"
                        style={{ width: '100%' }}
                        onClick={() => savePw()}
                    >
                        Opslaan
                    </Button>
                </div>
            </Form>
        </div>
    )
}

function Info(props) {

    const classes = useStyles();

    const history = useHistory();

    const [init, setInit] = useState(false);
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [nr, setNr] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");

    useEffect(() => {
        if (!jsonEmpty(props.client) && !init) {
            setName(props.client.name || "");
            setPhone(props.client.phone_number || "");
            setStreet(props.client.street || "");
            setNr(props.client.number || "");
            setZip(props.client.zip || "");
            setCity(props.client.city || "");
            setInit(true);
        }
    }, [props.client])

    const saveClient = () => {
        fetchPost(infoCall, {
            name: name,
            phone_number: phone,
            street: street,
            number: nr,
            zip: zip,
            city: city
        }, (data) => {
            props.fetchAccInfo();
        }, (err) => { })
    }

    const logout = () => {
        fetchPost(logout_call, {}, (data) => {
            history.push('login');
        }, (er) => { });
    }

    return (
        <div className={classes.creds_fields}>
            <Typography variant="body2" className="b2" style={{ margin: '15px 0' }}>
                Mijn gegevens:
            </Typography>
            <Form role="form">
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input placeholder={"Naam"}
                            value={name}
                            type="text"
                            id="street"
                            onChange={(e) => setName(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input placeholder={"Telefoonnummer"}
                            value={phone}
                            type="text"
                            id="street"
                            onChange={(e) => setPhone(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input placeholder={"Straat"}
                            value={street}
                            type="text"
                            id="street"
                            onChange={(e) => setStreet(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <InputGroup className={`input-group-alternative ${classes.nr}`}>
                        <Input placeholder={"Huisnummer + toevoeging"}
                            value={nr}
                            type="text"
                            id="number"
                            onChange={(e) => setNr(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input placeholder={"Postcode"}
                            value={zip}
                            type="text"
                            id="cip"
                            onChange={(e) => setZip(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                        <Input placeholder={"Plaats"}
                            value={city}
                            type="text"
                            id="city"
                            onChange={(e) => setCity(e.target.value)} />
                    </InputGroup>
                </FormGroup>
                <div className={classes.buttons}>
                    <Button
                        className="my-4"
                        color="warning"
                        type="button"
                        style={{ width: '100%' }}
                        onClick={() => saveClient()}
                    >
                        Opslaan
                    </Button>
                    <Button
                        className="my-4"
                        type="button"
                        style={{ width: '100%' }}
                        onClick={() => logout()}
                    >
                        Uitloggen
                    </Button>
                </div>
            </Form>
        </div>
    )
}