import { FormControlLabel, makeStyles, Paper, Switch } from '@material-ui/core';
import React from 'react';
import { Input, Button } from 'reactstrap';

const useStyles = makeStyles({
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'center'
    }
})

export default function TableToolbar(props) {

    const classes = useStyles();

    return (
        <Paper variant="outlined"
            elevation={1}
            id="table-toolbar">
            <div>
                {typeof props.onButtonClick !== 'undefined' &&
                    <Button color="primary"
                        disabled={props.button_disabled}
                        size="sm"
                        onClick={() => props.onButtonClick()}>
                        {props.buttontext}
                    </Button>}
                {typeof props.onSecondButtonClick !== 'undefined' &&
                    <Button color="danger"
                        disabled={props.second_button_disabled}
                        size="sm"
                        onClick={() => props.onSecondButtonClick()}>
                        {props.secondbuttontext}
                    </Button>}
            </div>
            <div className={classes.filter}>
                {"hidden" in props &&
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.hidden}
                                onChange={props.setHidden}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        labelPlacement="start"
                        style={{marginRight: 20}}
                        label="Verborgen systemen"
                    />
                }
                <Input className="toolbar-input"
                    type="text"
                    placeholder="Zoeken..."
                    onChange={(e) => props.changeSearch(e.target.value)} />
            </div>
        </Paper>
    )

}