import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Spinner } from 'reactstrap';
import { fetchGet } from '../../toolbox/fetchlib';

const verifyAccount = "/api/confirm_email";

export default function VerifyAccount (props) {

    const [message, setMessage] = useState("");
    const history = useHistory();

    useEffect(() => {
        var url = window.location.href;
        var index = url.indexOf("token="), token = "";
        if (index >= 0) {
            token = url.substring(index + 6, url.length);
            fetchGet(`${verifyAccount}?token=${token}`, (data) => {
                if (data.success) {
                    history.push('login');
                } else
                    setMessage(data.message);
            }, (er) => {setMessage(er.message)})
        }
    }, []);

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'}}>
            <Spinner style={{ width: 21, height: 21, marginBottom: 20 }} />
            {message === "" ? "Loading..." : message}
        </div>
    )

}