import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, FormGroup, Input, Spinner } from 'reactstrap';
import { fetchGet, fetchPost } from '../../toolbox/fetchlib';

import logo from './../../assets/img/fectum-logo.png';

const getAccountInfo = "/api/get_account_info";
const pushAcc = "/api/post_user_credentials";

const maxWidth = 197;

const useStyles = makeStyles({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    logo: {
        width: '98%',
        maxWidth: 300
    },
    fields: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '2vh'
    },
    new_acc_btn: {
        marginTop: '5vh'
    },
    message_sent: {
        height: 338,
        display: 'flex',
        alignItems: 'center'
    },
    err_msg: {
        maxWidth: 500,
        marginTop: 30,
        height: 50,
        color: 'red'
    }
});

export default function Login(props) {

    const classes = useStyles();

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [sent, setSent] = useState(false);

    const [create_err, setCreateErr] = useState(0);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [uname, setUname] = useState("");
    const [pword, setPword] = useState("");
    const [repword, setRepword] = useState("");

    useEffect(() => {
        fetchGet(getAccountInfo, (data) => {

        }, (er) => {

        });
    }, [])

    const createAccount = () => {
        if (name === "" || uname === "" || pword === "" || repword === "" || pword !== repword)
            setError(true);
        else {
            setLoading(true);
            fetchPost(pushAcc, {
                email: uname,
                password: pword,
                name: name,
                phone_number: "",
                verify_url: "monitor.solesta.nl/verify_account"
            }, (data) => {
                setSent(true);
            }, (er) => {
                setCreateErr(er.status);
                setLoading(false);
            })
        }
    }

    return (
        <div className={classes.root}>
            <img src={logo} className={classes.logo} alt="logo" />
            <div className={classes.err_msg}>
                {create_err === 450 &&
                    <Typography variant="body2">
                        Wachtwoord moet minstens 8 tekens lang zijn waarvan tenminste 1 hoofdletter, 1 cijfer en een speciaal teken.
                            </Typography>}
                {(create_err >= 300 && create_err !== 450) &&
                    <Typography variant="body2" style={{textAlign: 'center'}}>
                        Account aanmaken niet gelukt. Probeer het later opnieuw.
                            </Typography>}
            </div>
            {!sent &&
                <div className={classes.fields}>
                    <FormGroup>
                        <Input
                            id="name"
                            style={{ maxWidth: maxWidth }}
                            className={(error && name === "") ? "is-invalid" : ""}
                            placeholder="Naam"
                            type="text"
                            onKeyUp={(e) => setName(document.getElementById("name").value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            style={{ maxWidth: maxWidth }}
                            id="uname"
                            className={(error && uname === "") ? "is-invalid" : ""}
                            placeholder="Emailadres"
                            type="email"
                            onKeyUp={(e) => setUname(document.getElementById("uname").value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="pword"
                            style={{ maxWidth: maxWidth }}
                            className={(error && (pword === "" || pword !== repword)) ? "is-invalid" : ""}
                            placeholder="Wachtwoord"
                            type="password"
                            onKeyUp={(e) => setPword(document.getElementById("pword").value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            id="repword"
                            style={{ maxWidth: maxWidth }}
                            className={(error && (repword === "" || pword !== repword)) ? "is-invalid" : ""}
                            placeholder="Herhaal Wachtwoord"
                            type="password"
                            onKeyUp={(e) => { if (e.code === "Enter") createAccount(); else setRepword(document.getElementById("repword").value) }}
                        />
                    </FormGroup>
                    <Button color="info"
                        type="button"
                        disabled={loading}
                        style={{ width: '100%' }}
                        onClick={() => createAccount()}>
                        {loading ? <Spinner style={{ width: 21, height: 21 }} /> : "Account aanmaken"}</Button>
                </div>}
            {sent && <div className={classes.message_sent}>
                Email verstuurd. Klik op de link om uw account te bevestigen.
            </div>}
        </div>
    )

}