import {combineReducers} from 'redux';

import userInfoReducer from './user_info';
import originReducer from './origin';
import customerAccountReducer from './customer_info';

const allReducers = combineReducers({
    user_info: userInfoReducer,
    origin: originReducer,
    customer_info: customerAccountReducer
})

export default allReducers;