import React, { useState } from 'react';
import ConfirmationScreen from '../../../../assets/components/ConfirmationScreen';
import ExternalLinker from '../../../admin/components/unassigned_overview/ExternalLinker';
import SystemEditor from '../SystemEditor';
import TableToolbar from '../TableToolbar';
import ClientModal from './ClientModal';
import ClientsTable from './ClientsTable';

export default function ClientOverview(props) {

    const default_editor = { open: false, system: null, text: "", callback: null, name: "", user_id: null };
    const default_confirm = { open: false, text: "", callback: null };
    const defaultExternalLink = {open: false, client_id: ""};
    
    const [modal, setModal] = useState({ open: false, client: {} });
    const [search, setSearch] = useState("");
    const [editor, setEditor] = useState(default_editor)
    const [confirm, setConfirm] = useState(default_confirm)
    const [external_link, setExternalLink] = useState(defaultExternalLink);

    return (
        <div className="tab-overview">
            <TableToolbar changeSearch={(v) => setSearch(v)} />
            <ClientsTable data={props.data}
                search={search}
                installers={props.installers}
                reset_table={props.reset_table}
                chartData={props.chartData.bind(this)}
                setExternalLink={(id) => setExternalLink({open: true, client_id: id})}
                editSystem={(s, name, id) => setEditor({
                    open: true,
                    user_id: id,
                    system: s,
                    name: name,
                    text: s === null ? "Nieuw systeem koppelen" : "Systeem wijzigen",
                    callback: (s) => props.saveSystem(s, () => setEditor(default_editor))
                })}
                editClient={(c) => setModal({ open: true, client: c })} />
            <ClientModal open={modal.open}
                close={() => setModal({ open: false, client: {} })}
                installers={props.installers}
                text="Wijzig klant gegevens"
                client={modal.client}
                savePw={props.savePw.bind(this)}
                saveClient={props.updateClient.bind(this)} />
            {editor.open &&
                <SystemEditor open={editor.open}
                    installers={props.installers}
                    installer_data={props.installer_data}
                    system={editor.system}
                    user_id={editor.user_id}
                    name={editor.name}
                    close={() => setEditor(default_editor)}
                    text={editor.text}
                    save={(v) => editor.callback(v)} />}
            {external_link.open &&
                <ExternalLinker external={props.external}
                    open={external_link.open}
                    close={() => setExternalLink(defaultExternalLink)}
                    save={(args) => props.saveExternalLink(args, () => setExternalLink(defaultExternalLink))}
                    client_id={external_link.client_id} />}
            {confirm.open &&
                <ConfirmationScreen confirm={() => confirm.callback()}
                    open={confirm.open}
                    close={() => setConfirm(default_confirm)}>{confirm.text}</ConfirmationScreen>}
        </div>
    )

}