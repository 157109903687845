import React, { useState } from 'react';
import ConfirmationScreen from '../../../../assets/components/ConfirmationScreen';
import TableToolbar from '../TableToolbar';
import { InstallerData } from './InstallerData';
import InstallerModal from './InstallerModal';
import InstallerTable from './InstallerTable';

export default function InstallerOverview(props) {

    const { newInstaller, deleteInstallers, editInstaller } = InstallerData();

    const default_modal = {open: false, callback: null, installer: null};

    const [search, setSearch] = useState("");
    const [modal, setModal] = useState(default_modal);
    const [confirm_modal, setConfirmModal] = useState(false);
    const [selected, setSelected] = useState([]);

    const selectInstaller = (arg) => {
        if (arg === "all")
            setSelected(props.installers.map((inst) => inst.id));
        else if (arg === "none")
            setSelected([]);
        else {
            var index = selected.indexOf(arg), new_selected = [...selected];
            index >= 0 ? new_selected.splice(index, 1) : new_selected.push(arg);
            setSelected(new_selected);
        }
    }

    return (
        <div className="tab-overview">
            <TableToolbar changeSearch={(v) => setSearch(v)}
                onButtonClick={() => setModal({open: true, callback: newInstaller.bind(this), installer: null})}
                buttontext="Nieuw"
                onSecondButtonClick={() => setConfirmModal(true)}
                second_button_disabled={!selected.length}
                secondbuttontext="Verwijderen" />
            <InstallerTable data={props.installers}
                search={search}
                selected={selected}
                editInstaller={(inst) => setModal({open: true, callback: editInstaller.bind(this), installer: inst})}
                selectAll={() => selectInstaller("all")}
                deselectAll={() => selectInstaller("none")}
                editClient={(id) => selectInstaller(id)} />
            {modal.open && 
            <InstallerModal open={modal.open}
                close={() => setModal(default_modal)}
                installer={modal.installer}
                fetchInstallers={props.fetchInstallers.bind(this)}
                textAlign="left"
                confirm_button="Opslaan"
                saveInstaller={(data, cb, ecb) => modal.callback(data, cb, ecb)} />}
            <ConfirmationScreen open={confirm_modal}
                close={() => setConfirmModal(false)}
                textAlign="left"
                confirm_button="Bevestig"
                confirm={() => deleteInstallers(selected, () => {
                    props.fetchInstallers();
                }) & setConfirmModal(false)}>
                Weet je zeker dat je deze installateurs wil verwijderen?
            </ConfirmationScreen>
        </div>
    )

}