import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ChartData } from './ChartData';
import { LineChart } from './../../../../assets/components/LineChart';
import { Spinner } from 'reactstrap';
import { Toolbar } from './Toolbar';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    content: {
        padding: 15,
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#f5f7f9'
    }
})

export default function DataOverview(props) {

    const classes = useStyles();

    const { full_data, loading, dates, setDates, updateData } = ChartData(props.topics, props.client_id);

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Toolbar dates={dates}
                    navBack={props.navBack.bind(this)}
                    setDates={(start, end) => setDates({ start: start, end: end })}
                    client_id={props.client_id}
                    data_info={props.data_info}
                    updateData={updateData.bind(this)} />
                {(typeof full_data !== 'undefined' && !loading) &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <LineChart title="Temperatuur Overzicht" title_5="Vat boven" title_2="Vat onder" title_3="Collector"
                                title_1="Pomp %"
                                title_4="Extern"
                                data={full_data["pump01pwm"]}
                                data_5={full_data["temp02"]}
                                data_2={full_data["temp03"]}
                                data_3={full_data["temp01"]}
                                data_4={full_data["temp04"]}
                                loading={loading} />
                        </Grid>
                    </Grid>
                }
                {(typeof full_data === 'undefined' || loading) &&
                    <Spinner />
                }
            </div>

        </div>
    )

}