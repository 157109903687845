import React from 'react';
import { Button, Modal } from 'reactstrap';

export default function ConfirmationScreen(props) {

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={props.open}
            toggle={() => props.close()}
        >
            
            <div className="modal-body">
                <p style={{fontWeight: 'bold', textAlign: props.textAlign}}>
                    {props.children}
                </p>
            </div>
            <div className="modal-footer">
                <Button color="primary" type="button" onClick={() => props.confirm()}>
                    {typeof props.confirm_button !== 'undefined' ? props.confirm_button : "Opslaan"}
                </Button>
                <Button
                    className="ml-auto"
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => props.close()}
                >
                    {typeof props.cancel_button !== 'undefined' ? props.cancel_button : "Annuleren"}
                </Button>
            </div>
        </Modal>
    )
}