import React, { useEffect, useState } from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { Button, Card, CardBody, FormGroup, Input, Modal } from 'reactstrap';
import { Dropdown } from '../../../../assets/components/CustomItems';
import { failedNotification } from '../../../../toolbox/notification-center';

export default function SystemLinker (props) {

    const [installer, setInstaller] = useState(-1);
    const [street, setStreet] = useState("");
    const [number, setNumber] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");

    const save = () => {
        if (installer !== -1) {
            var args = {
                client_id: props.client_id,
                installer: props.installers[installer].id,
                street: street,
                number: number,
                zip: zip,
                city: city
            };
            props.save(args);
        } else {
            failedNotification("Niet gelukt.", "Selecteer een installateur.");
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={props.open}
            toggle={() => props.close()}
        >
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            Systeem koppelen
                        </div>
                        {props.installers.length > 0 &&
                            <Dropdown options={props.installers.map((inst) => { return { label: inst.name, key: inst.id } })}
                                onChange={(v) => setInstaller(v)}
                                value={installer}
                                color="primary"
                                style={{ width: '100%', marginBottom: 20 }}
                                title="Installateur" />}
                        <FormGroup>
                            <Input
                                id="street"
                                placeholder="Straatnaam"
                                type="text"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="number"
                                placeholder="Huisnummer + toevoeging"
                                type="text"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="zip"
                                placeholder="Postcode"
                                type="text"
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="city"
                                placeholder="Plaatsnaam"
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </FormGroup>
                        
                        <div className="text-center">
                            <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                style={{ width: 130 }}
                                onClick={() => save()}
                            >
                                Opslaan
                                </Button>
                            <Button
                                className="my-4"
                                type="button"
                                style={{ width: 130 }}
                                onClick={() => props.close()}
                            >
                                Annuleren
                                </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Modal >
    )

}

{/**

<Typography variant="body2" className={`${classes.b2} b2`}>
                            Collector (optioneel)
                        </Typography>
                        <FormGroup className={classes.tn_fields}>
                            <Input
                                placeholder="AAA"
                                type="text"
                                value={tn_1_col}
                                className={classes.tn_1}
                                onChange={(e) => setTn1Col(e.target.value)}
                            />
                            /
                            <Input
                                placeholder="00"
                                type="text"
                                value={tn_2_col}
                                className={classes.tn_2}
                                onChange={(e) => setTn2Col(e.target.value)}
                            />
                            -
                            <Input
                                placeholder="000000"
                                type="text"
                                value={tn_3_col}
                                className={classes.tn_3}
                                onChange={(e) => setTn3Col(e.target.value)}
                            />
                        </FormGroup>
                        <Typography variant="body2" className={`${classes.b2} b2`}>
                            Opslagmodule (optioneel)
                        </Typography>
                        <FormGroup className={classes.tn_fields}>
                            <Input
                                placeholder="AAA"
                                type="text"
                                value={tn_1_op}
                                className={classes.tn_1}
                                onChange={(e) => setTn1Op(e.target.value)}
                            />
                            /
                            <Input
                                placeholder="00"
                                type="text"
                                value={tn_2_op}
                                className={classes.tn_2}
                                onChange={(e) => setTn2Op(e.target.value)}
                            />
                            -
                            <Input
                                placeholder="000000"
                                type="text"
                                value={tn_3_op}
                                className={classes.tn_3}
                                onChange={(e) => setTn3Op(e.target.value)}
                            />
                        </FormGroup>
                        <Typography variant="body2" className={`${classes.b2} b2`}>
                            Pompmodule (optioneel)
                        </Typography>
                        <FormGroup className={classes.tn_fields}>
                            <Input
                                placeholder="AAA"
                                type="text"
                                value={tn_1_pomp}
                                className={classes.tn_1}
                                onChange={(e) => setTn1Pomp(e.target.value)}
                            />
                            /
                            <Input
                                placeholder="00"
                                type="text"
                                value={tn_2_pomp}
                                className={classes.tn_2}
                                onChange={(e) => setTn2Pomp(e.target.value)}
                            />
                            -
                            <Input
                                placeholder="000000"
                                type="text"
                                value={tn_3_pomp}
                                className={classes.tn_3}
                                onChange={(e) => setTn3Pomp(e.target.value)}
                            />
                        </FormGroup>
*/}