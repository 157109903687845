import { Typography } from '@material-ui/core';
import React from 'react';
import Banner from '../../../assets/components/Banner';
import Footer from '../../../assets/components/Footer';



export default function BoilerDisconnected(props) {


    return (
        <div className="full-page">
            <Banner />
            <div id="system_info">
                <div style={{width: '99%', maxWidth: 500, margin: '0 auto'}}>
                    <Typography variant="body1" className="b1" style={{ margin: '10px 0' }}>Kan geen verbinding maken met uw systeem. Controleer de internet verbinding van uw systeem.</Typography>
                </div>
            </div>
            <Footer />
        </div>
    )

}