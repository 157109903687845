import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal
} from "reactstrap";
import { Dropdown } from '../../../../assets/components/CustomItems';
import { jsonEmpty } from './../../../../toolbox/utils';


const useStyles = makeStyles({
    nr: {
        width: '48%',
        marginRight: '2%',
        display: 'inline-block'
    },
    add: {
        width: '48%',
        marginLeft: '2%',
        display: 'inline-block'
    }
})

export default function InstallerModal(props) {

    const classes = useStyles();

    const [init, setInit] = useState(false);

    const [name, setName] = useState("");
    const [contact_person, setContactPerson] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [location, setLocation] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        if (!init && "installer" in props && props.installer !== null) {
            setContactPerson(props.installer.contact_person || "");
            setName(props.installer.name);
            setEmail(props.installer.email);
            setLocation(props.installer.location);
            setPhone(props.installer.phone_number);
        }

    }, [props.installer])

    const saveInstaller = () => {
        if (props.installer !== null) {
            if (contact_person !== "" && name !== "" && email !== "" && location !== "" && phone !== "") {
                var args = {
                    id: props.installer.id,
                    name: name,
                    contact_person: contact_person,
                    email: email,
                    location: location,
                    phone_number: phone
                };
                if (password !== "")
                    args.password = password;
                props.saveInstaller(args, () => {
                    props.fetchInstallers();
                    props.close();
                }); 
            }
        } else {
            if (contact_person !== "" && name !== "" && email !== "" && password !== "" && location !== "" && phone !== "") {
                props.saveInstaller({
                    name: name,
                    contact_person: contact_person,
                    email: email,
                    password: password,
                    location: location,
                    phone_number: phone
                }, () => {
                    props.fetchInstallers();
                    props.close();
                }, (message) => {
                    console.log(message);
                });
            }
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={props.open}
            toggle={() => props.close()}
        >
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            {props.installer !== null ? "Installateur wijzigen" : "Nieuwe installateur"}
                        </div>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Naam"}
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Contactpersoon"}
                                        type="text"
                                        id="contact_person"
                                        value={contact_person}
                                        onChange={(e) => setContactPerson(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Email"}
                                        type="text"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Wachtwoord"}
                                        type="text"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Stad"}
                                        type="text"
                                        id="location"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Telefoon"}
                                        type="text"
                                        id="phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                                <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    style={{ width: 130 }}
                                    onClick={() => saveInstaller()}
                                >
                                    Opslaan
                                </Button>
                                <Button
                                    className="my-4"
                                    type="button"
                                    style={{ width: 130 }}
                                    onClick={() => props.close()}
                                >
                                    Annuleren
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    )
}