import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { Checkbox, Tooltip } from '@material-ui/core';

function filter(data, search) {
    if (search === "")
        return data;
    return data.filter((dp) => (dp.street + dp.number + dp.addition + dp.zip + dp.city).toLowerCase().includes(search.replace(/\s/g, "").toLowerCase()))
}

function descendingComparator(a, b, orderBy, order) {
    if (b[orderBy] === "-")
        return order === "asc" ? 1 : -1;
    if (a[orderBy] === "-")
        return order === "asc" ? -1 : 1;

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, order)
        : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function InstallerTable(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const sortClick = (key) => {
        if (key === orderBy && order === "asc") {
            setOrder("desc");
        } else {
            setOrderBy(key);
            setOrder('asc');
        }
    }

    return (
        <Paper className="table-root" variant="outlined">
            <TableContainer className="table-container">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 100, fontWeight: 'bold' }} align="center">
                                <Checkbox
                                    style={{ padding: 0 }}
                                    checked={props.selected.length === props.data.length && props.data.length > 0}
                                    indeterminate={props.selected.length === props.data.length && props.data.length > 0}
                                    inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                                    onChange={() => props.selected.length === props.data.length ? props.deselectAll() : props.selectAll()}
                                />
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "name"}
                                    direction={orderBy === "name" ? order : 'asc'}
                                    onClick={() => sortClick("name")}>
                                    Naam
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "contact_person"}
                                    direction={orderBy === "contact_person" ? order : 'asc'}
                                    onClick={() => sortClick("contact_person")}>
                                    Contactpersoon
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "email"}
                                    direction={orderBy === "email" ? order : 'asc'}
                                    onClick={() => sortClick("email")}>
                                    Email
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "location"}
                                    direction={orderBy === "location" ? order : 'asc'}
                                    onClick={() => sortClick("location")}>
                                    Stad
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                Telefoonnummer
                            </TableCell>
                            <TableCell className="table-cell">
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.length ? filter(stableSort(props.data, getComparator(order, orderBy)), props.search).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                    <TableCell className="table-actions">
                                        <Checkbox color="primary"
                                            style={{ padding: 0 }}
                                            checked={props.selected.includes(row.id)}
                                            onChange={(e) => props.editClient(row.id)} />
                                    </TableCell>
                                    <TableCell>
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        {row.contact_person || ""}
                                    </TableCell>
                                    <TableCell>
                                        {row.email}
                                    </TableCell>
                                    <TableCell>
                                        {row.location}
                                    </TableCell>
                                    <TableCell>
                                        {row.phone_number}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Aanpassen">
                                            <Button size="sm"
                                                id={`edit-btn-${(i + page * rowsPerPage)}`}
                                                color="primary"
                                                onClick={() => props.editInstaller(row)}>
                                                <i className="fa fa-edit"></i>
                                            </Button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        }) : <TableRow></TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
