import React, { useEffect, useRef, useState } from 'react';
import { fetchGet } from '../../../../toolbox/fetchlib';

const dataApi = "/api/data";

const desiredTopics = ["temp01", "temp02", "temp03", "temp04", "pump01pwm"];

export const ChartData = (tops, client_id) => {

    const [dates, setDates] = useState({
        start: (new Date().getTime() / 1000 - 604800),
        end: new Date().getTime() / 1000
    });

    const data = useRef({});

    const [full_data, setFullData] = useState()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        updateData();
    }, [])

    const updateData = () => {
        setLoading(true);
        fetchData([...tops]);
    }

    const fetchData = (topics) => {
        var topic = topics.pop();
        if ('param' in topic) {
            fetchGet(dataApi + "?client_id=" + client_id + "&topic=" + encodeURIComponent(topic.param) + "&min_stamp=" + Math.round(dates.start) + "&max_stamp=" + Math.round(dates.end), (resp) => {
                data.current = { ...data.current, [topic.name]: resp };
                if (topics.length === 0) {
                    var new_data = {};
                    desiredTopics.map((top) => { if (top in data.current) new_data[top] = data.current[top] });
                    setFullData(new_data);
                    setLoading(false);
                } else
                    fetchData(topics);
            })
        }
    }

    return { full_data, loading, dates, setDates, updateData }

}