import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import Loading from './Loading';

const useStyles = makeStyles({
    root: {
        padding: 20,
        position: 'relative'
    }
});

const red = "#d10d0d";
const blue = "#1e28d5";
const yellow = "#f8bb19";
const green = "#02690b";
const gray = "#636363";

export function LineChart(props) {

    const classes = useStyles();

    const options = {
        chart: {
            height: 350,
            type: 'area',
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: false
            },
            animations: {
                enabled: false
            }
        },
        toolbar: {
            show: false,
            tools: {
                download: false, pan: false, zoomin: false
            }
        },
        markers: {
            size: 0
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            shared: true,
            theme: "dark",
            x: {
                format: 'HH:mm - dd MMM yyyy',
                show: false
            }
        },
        colors: "data_2" in props ? [gray, blue, yellow, green, red] : [blue],
        title: {
            text: 'Default',
            align: 'left'
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false // deze ook bij Fectum
            }
        },
    };

    const parseData = (dat) => {
        var topic_data = [];
        dat.stamps.map((stamp, i) => topic_data.push([stamp * 1000, dat.vals[i]]));
        return topic_data;
    }

    return (
        <Paper className={classes.root}>
            {props.loading && <Loading />}
            <div id="chart">
                <Chart type="line" height={550}
                    series={typeof props.data === "undefined" ? [] : "data_2" in props ? [{
                        name: props.title_1,
                        data: parseData(props.data)
                    },
                    {
                        name: props.title_2,
                        data: parseData(props.data_2)
                    },
                    {
                        name: props.title_3,
                        data: parseData(props.data_3)
                    },
                    {
                        name: props.title_4,
                        data: parseData(props.data_4)
                    },
                    {
                        name: props.title_5,
                        data: parseData(props.data_5)
                    },
                ] : [{
                        name: props.title,
                        data: parseData(props.data)
                    }]}
                    options={{
                        ...options,
                        title: {
                            ...options.title,
                            text: props.title
                        }
                    }} />
            </div>
        </Paper>
    )
};