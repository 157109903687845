export const set_user_info = (user_info) => {
    return {
        type: "SET_USER_INFO",
        payload: user_info
    }
};

export const set_origin = (origin) => {
    return {
        type: "SET_ORIGIN",
        payload: origin
    }
};

export const reset_origin = () => {
    return {
        type: "RESET_ORIGIN"
    }
};

export const set_cust_acc_info = (info) => {
    return {
        type: "SET_CUSTOMER_ACCOUNT_INFO",
        payload: info
    }
}