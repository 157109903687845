import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import SmallBanner from '../../assets/components/SmallBanner';
import { set_origin } from "../../redux/actions";
import ClientOverview from './components/clients_overview/ClientOverview';

import './style/Installer.scss';
import DataOverview from './components/data_overview/DataOverview';
import InstallerData from './InstallerData';

const desiredTopics = ["temp01", "temp02", "temp03", "temp04", "pump01pwm"];

export default function Installer(props) {

    const { 
        installers,
        installer_data, 
        external,
        topics, 
        cust_data, 
        loading, 
        reset_table,
        saveExternalLink,
        fetchData, 
        unlink, 
        fetchTopics,
        updateClient,
        deleteUser,
        saveSystem,
        savePw,
        toggleHide
    } = InstallerData();

    const user_info = useSelector(state => state.user_info);
    const history = useHistory();
    const dispatch = useDispatch();

    const [tab, setTab] = useState('klanten');
    const [prev_tab, setPrevTab] = useState('');
    const [id, setID] = useState("");
    const [chart_data, setChartData] = useState({});

    useEffect(() => {
        if (!user_info.initialized) {
            dispatch(set_origin('installateur'));
            history.push('/');
        } else {
            fetchTopics();
        }
    }, []);

    return (
        <div id="admin-container">
            <SmallBanner tab={tab} changeTab={(val) => setTab(val)} user_info={user_info} />
            {tab === "klanten" &&
                <ClientOverview installers={installers} 
                    installer_data={installer_data}
                    external={external}
                    data={cust_data}
                    reset_table={false}
                    saveExternalLink={saveExternalLink.bind(this)}
                    deleteUser={deleteUser.bind(this)}
                    saveSystem={saveSystem.bind(this)}
                    savePw={savePw.bind(this)}
                    updateClient={updateClient.bind(this)}
                    chartData={(id, info) => setID(id) & setTab("data") & setPrevTab("klanten") & setChartData(info)} />}
            {tab === "data" &&
                <DataOverview topics={topics} client_id={id} data_info={chart_data} desiredTopics={desiredTopics} navBack={() => setTab(prev_tab) & setID("") & setChartData({})} />}
        </div>
    )
}