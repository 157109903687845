import React, { useState } from 'react';
import ConfirmationScreen from '../../../../assets/components/ConfirmationScreen';
import TableToolbar from '../TableToolbar';
import ExternalLinker from './ExternalLinker';
import SystemLinker from './SystemLinker';
import { UnassignedData } from './UnassignedData';
import UnassignedTable from './UnassignedTable';

export default function UnassignedOverview(props) {

    const { clients, selected, loading, updateClients, selectClient } = UnassignedData();

    const defaultSystemLink = {open: false, client_id: ""};
    const defaultExternalLink = {open: false, client_id: ""};

    const [search, setSearch] = useState("");
    const [modal, setModal] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [system_link, setSystemLink] = useState(defaultSystemLink);
    const [external_link, setExternalLink] = useState(defaultExternalLink);

    return (
        <div className="tab-overview">
            <TableToolbar changeSearch={(v) => setSearch(v)} 
                button_disabled={selected.length === 0}
                hidden={hidden}
                setHidden={() => setHidden(cur => !cur)}/>
            <UnassignedTable data={props.data}
                search={search}
                selected={selected}
                hidden={hidden}
                installers={props.installers}
                installers_dict={props.installers_dict}
                external={props.external}
                external_dict={props.external_dict}
                openSystemLink={(id) => setSystemLink({open: true, client_id: id})}
                openExternalLink={(id) => setExternalLink({open: true, client_id: id})}
                toggleHide={props.toggleHide.bind(this)}
                chartData={props.chartData.bind(this)}
                unlink={props.unlink.bind(this)}
                unlinkManual={props.unlinkManual.bind(this)}
                unlinkManualExternal={props.unlinkManualExternal.bind(this)}
                selectAll={() => selectClient("all")}
                deselectAll={() => selectClient("none")}
                editClient={(id) => selectClient(id)} />
            {system_link.open && 
                <SystemLinker installers={props.installers}
                    open={system_link.open}
                    close={() => setSystemLink(defaultSystemLink)}
                    save={(args) => props.saveSystemLink(args, () => setSystemLink(defaultSystemLink))}
                    client_id={system_link.client_id}/>}
            {external_link.open && 
                <ExternalLinker external={props.external}
                    open={external_link.open}
                    close={() => setExternalLink(defaultExternalLink)}
                    save={(args) => props.saveExternalLink(args, () => setExternalLink(defaultExternalLink))}
                    client_id={external_link.client_id}/>}
            <ConfirmationScreen open={modal}
                close={() => setModal(false)}
                textAlign="left"
                confirm_button="Bevestig"
                confirm={() => updateClients() & setModal(false)}>
                Weet je zeker dat je deze klanten wilt toewijzen aan Solesta?         
            </ConfirmationScreen>
        </div>
    )

}