import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, FormGroup, Input, Spinner } from 'reactstrap';
import { fetchGet, fetchPost } from '../../toolbox/fetchlib';

import logo from './../../assets/img/fectum-logo.png';

const loginCall = "/api/login";
const getAccountInfo = "/api/get_account_info";

const useStyles = makeStyles({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    logo: {
        width: '98%',
        maxWidth: 300
    },
    fields: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '5vh'
    },
    new_acc_btn: {
        marginTop: '5vh'
    }
});

export default function Login(props) {

    const classes = useStyles();

    const [login, setLogin] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        fetchGet(getAccountInfo, (data) => {
            //history.push("/") 
        }, () => {console.log('failed to login')});
    }, [])

    const postLogin = () => {
        setLoading(true);
        const uname = document.getElementById('uname').value;
        const pword = document.getElementById('pword').value;
        fetchPost(loginCall, { email: uname, password: pword }, (data) => {
            setLoading(false);
            history.push(`/`);
        }, (data) => {
            setLoading(false);
            setError(true);
        })
    }

    const newAccount = () => {
        history.push(`nieuw-account`);
    }

    return (
        <div className={classes.root}>
            <img src={logo} className={classes.logo} alt="logo" />
            <div className={classes.fields}>
                <Typography style={{textAlign: 'center', marginBottom: 10}}>{error ? "Wachtwoord incorrect" : ""}</Typography>
                <FormGroup>
                    <Input
                        id="uname"
                        placeholder="naam@voorbeeld.nl"
                        type="email"
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        id="pword"
                        placeholder="Wachtwoord"
                        type="password"
                        onKeyUp={(e) => { if (e.code === "Enter") postLogin() }}
                    />
                </FormGroup>
                <Button color="info"
                    type="button"
                    disabled={loading}
                    style={{ width: '100%' }}
                    onClick={() => postLogin()}>
                    {loading ? <Spinner style={{ width: 21, height: 21 }} /> : "Login"}</Button>
                <Button color="info"
                    type="button"
                    disabled={loading}
                    outline
                    className={classes.new_acc_btn}
                    onClick={() => newAccount()}>
                    Nieuw account
                </Button>
                <Button color="info"
                    type="button"
                    disabled={loading}
                    outline
                    className={classes.new_acc_btn}
                    onClick={() => history.push("/wachtwoord-vergeten")}>
                    Wachtwoord vergeten
                </Button>
            </div>

        </div>
    )

}