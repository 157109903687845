import React, { useEffect, useState } from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { Button, Card, CardBody, FormGroup, Input, Modal } from 'reactstrap';
import { Dropdown } from '../../../../assets/components/CustomItems';
import { failedNotification } from '../../../../toolbox/notification-center';

export default function ExternalLinker (props) {

    const [exter, setExter] = useState(-1);

    const save = () => {
        if (exter !== -1) {
            var args = {
                client_id: props.client_id,
                external: props.external[exter].id,
            };
            props.save(args);
        } else {
            failedNotification("Niet gelukt.", "Selecteer een installateur.");
        }
    }

    return (
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={props.open}
            toggle={() => props.close()}
        >
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            Extern koppelen
                        </div>
                        {props.external.length > 0 &&
                            <Dropdown options={props.external.map((ext) => { return { label: ext.name, key: ext.id } })}
                                onChange={(v) => setExter(v)}
                                value={exter}
                                color="primary"
                                style={{ width: '100%', marginBottom: 20 }}
                                title="Extern" />}
                        
                        <div className="text-center">
                            <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                style={{ width: 130 }}
                                onClick={() => save()}
                            >
                                Opslaan
                                </Button>
                            <Button
                                className="my-4"
                                type="button"
                                style={{ width: 130 }}
                                onClick={() => props.close()}
                            >
                                Annuleren
                                </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Modal >
    )

}