import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    Row,
    Button
} from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from 'moment';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: "center",
        marginBottom: 20
    },
    dates_row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    back_btn: {
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

export function Toolbar(props) {

    const classes = useStyles();

    const [state, setState] = useState({});

    useEffect(() => {
        setState({
            startDate: moment.unix(props.startDate),
            endDate: moment.unix(props.endDate),
        })
    }, [])

    return (
        <div className={classes.root}>
            <Row>
                <Col>
                    <div className={classes.back_btn} onClick={() => props.navBack()}>{"< Terug naar overzicht"}</div>
                    Client ID: {(props.data_info.zip || null) !== null && props.data_info.zip !== "-" && (props.data_info.number || null) !== null && props.data_info.number !== "-" ? props.data_info.zip + " - " + props.data_info.number + ` (${props.client_id})` : props.client_id}
                </Col>
            </Row>
            <Row className={classes.dates_row}>
                <Col xs={4} >
                    <FormGroup style={{ marginBottom: 0 }}>
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Startdatum"
                                }}
                                timeFormat={true}
                                renderDay={(pro, currentDate, selectedDate) => {
                                    let classes = pro.className;
                                    if (
                                        moment.unix(props.dates.start) &&
                                        moment.unix(props.dates.end) &&
                                        moment.unix(props.dates.start)._d + "" === currentDate._d + ""
                                    ) {
                                        classes += " start-date";
                                    } else if (
                                        moment.unix(props.dates.start) &&
                                        moment.unix(props.dates.end) &&
                                        new Date(moment.unix(props.dates.start)._d + "") <
                                        new Date(currentDate._d + "") &&
                                        new Date(moment.unix(props.dates.end)._d + "") >
                                        new Date(currentDate._d + "")
                                    ) {
                                        classes += " middle-date";
                                    } else if (
                                        moment.unix(props.dates.end) &&
                                        moment.unix(props.dates.end)._d + "" === currentDate._d + ""
                                    ) {
                                        classes += " end-date";
                                    }
                                    return (
                                        <td {...pro} className={classes}>
                                            {currentDate.date()}
                                        </td>
                                    );
                                }}
                                onChange={e => props.setDates(e.unix(), props.dates.end)}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup style={{ marginBottom: 0 }}>
                        <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="ni ni-calendar-grid-58" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Einddatum"
                                }}
                                timeFormat={false}
                                renderDay={(pro, currentDate, selectedDate) => {
                                    let classes = pro.className;
                                    if (
                                        moment.unix(props.dates.start) &&
                                        moment.unix(props.dates.end) &&
                                        moment.unix(props.dates.start)._d + "" === currentDate._d + ""
                                    ) {
                                        classes += " start-date";
                                    } else if (
                                        moment.unix(props.dates.start) &&
                                        moment.unix(props.dates.end) &&
                                        new Date(moment.unix(props.dates.start)._d + "") <
                                        new Date(currentDate._d + "") &&
                                        new Date(moment.unix(props.dates.end)._d + "") >
                                        new Date(currentDate._d + "")
                                    ) {
                                        classes += " middle-date";
                                    } else if (
                                        moment.unix(props.dates.end) &&
                                        moment.unix(props.dates.end)._d + "" === currentDate._d + ""
                                    ) {
                                        classes += " end-date";
                                    }
                                    return (
                                        <td {...pro} className={classes}>
                                            {currentDate.date()}
                                        </td>
                                    );
                                }}
                                onChange={e => props.setDates(props.dates.start, e.unix())}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col sm={1}>
                    <Button color="primary" size="md" type="button" onClick={() => props.updateData()}>
                        Update
                </Button>
                </Col>
            </Row>
        </div>
    )
}