import userEvent from '@testing-library/user-event';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchGet, fetchPost } from '../../toolbox/fetchlib';
import { failedNotification, successNotification } from '../../toolbox/notification-center';

const getCustomers = "/api/customers";
const disconnect = "/api/disconnect_smartline";
const topicCall = "/api/topics";
const changeClient = "/api/post_user_info";
const post_creds = "/api/connect_smartline";
const delete_user = "/api/delete_users";
const hide_ids = "/api/hide_client_ids";
const change_pw_external = "/api/change_password_external";
const get_installers = "/api/get_installers";
const get_externals = "/api/get_externals";
const manual_set = "/api/get_manual_installer_links";
const manual_external_link = "/api/manual_external_link";

const desiredTopics = ["temp01", "temp02", "temp03", "temp04", "pump01pwm"];

function assignData(client, customers) {
    client.street = "-";
    client.number = "-";
    client.zip = "-";
    client.city = "-";
    client.user_id = "";
    client.trace_number = {};
    client.name = "";
    for (var i = 0; i < customers.length; i++) {
        for (var j = 0; j < customers[i].client_ids.length; j++) {
            if (customers[i].client_ids[j].client_id === client.client_id) {
                var c = customers[i];
                client.street = c.street === null ? "-" : (c.street || "-");
                client.number = c.number === null ? "-" : (c.number || "-");
                client.zip = c.zip === null ? "-" : (c.zip || "-");
                client.city = c.city === null ? "-" : (c.city || "-");
                client.user_id = c.id;
                client.name = c.client_ids[j].name;
                if ("trace_number" in customers[i].client_ids[j]) {
                    client.trace_number = customers[i].client_ids[j].trace_number;
                }
                return;
            }
        }
    }
}

export default function InstallerData(props) {

    const [loading, setLoading] = useState(false);
    const [cust_data, setCustData] = useState([]);
    const [installers, setInstallers] = useState({});
    const [external, setExternal] = useState([]);
    const [installer_data, setInstallerData] = useState({});
    const [topics, setTopics] = useState([]);
    const [reset_table, setResetTable] = useState(false);
    const [acc_info_set, setAccInfoSet] = useState(false);

    const acc_info = useSelector(state => state.user_info);

    useEffect(() => {
        if (acc_info.initialized && !acc_info_set) {
            fetchInstallers();
        }
    }, [acc_info])

    useEffect(() => {
        console.log(installer_data)
    }, [installer_data])

    const fetchInstallers = () => {
        fetchGet(get_installers, (resp) => {
            resp.installers.map((inst) => inst.name === acc_info.name ? setInstallerData(inst) : "");
            setInstallers(resp.installers);
            fetchData();
        }, (er) => { failedNotification("Niet gelukt.", "Kan installateurs niet ophalen.") })
    }

    const fetchExternal = () => {
        fetchGet(get_externals, (resp) => {
            setExternal(resp.externals)
        }, (er) => { failedNotification("Niet gelukt.", "Kan installateurs niet ophalen.") })
    }

    const fetchTopics = () => {
        fetchGet(topicCall, (resp) => {
            var new_topics = [];
            resp.topics.map((topic) => {
                var split = topic.split("/");
                if (desiredTopics.includes(split[split.length - 1])) new_topics.push({ name: split[split.length - 1], param: topic });
            })
            setTopics(new_topics);
        }, () => { console.log("can't fetch topics") })
    }

    const fetchData = () => {
        setLoading(true);
        fetchGet(getCustomers, (data) => {
            if (data.length > 0)
                data = data.filter((item) => {
                    if (item.client_ids.length === 0)
                        return false;
                    if (item.client_ids[0].installer === installer_data.id)
                        return true;
                    return false;
                });
            fetchManuallyset(data);
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan klant niet ophalen. Probeer het later opnieuw");
        })
    }

    const fetchManuallyset = (prev_data) => {
        fetchGet(manual_set, (data) => {
            Object.keys(data).map((key) => {
                prev_data.push({
                    city: data[key].city,
                    client_ids: [{
                        client_id: key,
                        coupled_stamp: 0,
                        installer: data[key].installer,
                        name: (data[key].zip || "") !== "" || (data[key].number || "") !== "" ?
                            `${data[key].zip} ${data[key].number}` :
                            key
                    }],
                    email: "Handmatig gekoppeld",
                    id: null,
                    number: data[key].number,
                    street: data[key].street,
                    zip: data[key].zip
                })
            })
            setCustData(prev_data);
            fetchExternal();
            setResetTable(true);
            setLoading(false);
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan handmatig gekoppelde systemen niet ophalen. Probeer het later opnieuw");
        })

    }

    const unlink = (client) => {
        fetchPost(disconnect, {
            client_id: client.client_id,
            id: client.user_id
        }, (data) => {
            fetchData();
        }, (er) => { failedNotification("Unlink mislukt.", "Probeer het later opnieuw."); });
    }

    const updateClient = (client) => {
        fetchPost(changeClient, client, (data) => {
            fetchData();
        }, (er) => {
            failedNotification("Opslaan mislukt", "Kan nu niet opslaan. Probeer het later opnieuw.");
        })
    }

    const saveSystem = (system, callback) => {
        fetchPost(post_creds, system, (data) => {
            if (data.success) {
                fetchData();
                callback();
            }
            else if (data.status === 1)
                failedNotification("Apparaat ID niet gevonden.", "Apparaat ID bestaat niet. Controleer of dit veld juist is ingevuld.")
            else if (data.status === 2)
                failedNotification("Apparaat ID al gekoppeld.", "Apparaat ID al gekoppeld aan een ander apparaat. Neem contact op met Solesta.")
            else
                failedNotification("Koppelen mislukt.", "Probeer het later opnieuw.");
        }, (er) => { failedNotification("Koppelen mislukt.", "Probeer het later opnieuw."); })
    }

    const savePw = (pw, id) => {
        fetchPost(change_pw_external, { new_password: pw, id: id }, (data) => {
            successNotification("Wachtwoord gewijzigd", "")
        }, (er) => {
            if (er.status === 450)
                failedNotification("Niet gelukt", "Wachtwoord moet minimaal 8 karakters bevatten waaronder een hoofdletter, speciaal teken en cijfer.")
            else
                failedNotification("Niet gelukt", "Probeer het later opniew.")
        })
    }

    const deleteUser = (id) => {
        fetchPost(delete_user, { ids: [id] }, (data) => {
            fetchData();
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan klant niet verwijderen. Probeer het later opnieuw.")
        })
    }

    const toggleHide = (id, hide) => {
        fetchPost(hide_ids, { client_ids: { [id]: hide } }, (data) => {
            fetchData();
        }, (er) => {
            failedNotification("Niet gelukt.", "Verbergen mislukt, probeer later opnieuw.");
        })
    }

    const saveExternalLink = (args, callback) => {
        fetchPost(manual_external_link, args, (data) => {
            callback();
            fetchData();
            successNotification("Opgeslagen.", "Externe is gekoppeld aan het systeem.")
        }, (er) => {
            failedNotification("Niet gelukt.", "Koppeling mislukt, probeer later opnieuw.");
        })
    }

    return {
        installers,
        installer_data,
        external,
        topics,
        cust_data,
        loading,
        reset_table,
        saveExternalLink,
        fetchData,
        unlink,
        fetchTopics,
        updateClient,
        deleteUser,
        saveSystem,
        savePw,
        toggleHide
    };

}