import { useEffect, useState } from "react";
import { fetchGet, fetchPost } from "../../../../toolbox/fetchlib";
import { failedNotification } from "../../../../toolbox/notification-center";

const getClients = "/api/noprefix_client_ids";
const getAllClients = "/api/all_my_client_ids";
const claimClients = "/api/claim_client_ids";

export const UnassignedData = () => {

    const [clients, setClients] = useState([]);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchNoPrefix();
    }, [])

    const fetchNoPrefix = () => {
        setLoading(true);
        fetchGet(getClients, (data) => {
            data.map((el) => el.assigned = "Niet toegewezen");
            fetchAll(data);
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan systemen niet ophalen. Probeer het later opnieuw");
        })
    }

    const fetchAll = (prefix) => {
        fetchGet(getAllClients, (data) => {
            data.map((el) => el.assigned = "Solesta");
            setClients(prefix.concat(data));
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan systemen niet ophalen. Probeer het later opnieuw");
        })
    }

    const selectClient = (id) => {
        if (id === "all")
            setSelected(clients.map((cl) => {if (cl.assigned === "Niet toegewezen") return cl.client_id}));
        else if (id === "none")
            setSelected([])
        else {
            var index = selected.indexOf(id), new_selected = [...selected];
            if (index >= 0)
                new_selected.splice(index, 1)
            else
                new_selected.push(id);
            setSelected(new_selected);
        }
    }

    const updateClients = () => {
        var args = [];
        for (var i=0; i<selected.length; i++) {
            for (var j=0; j<clients.length; j++) {
                if (clients[j].client_id === selected[i]) {
                    args.push(clients[j].client_id);
                }
            }
        }
        fetchPost(claimClients, {client_ids: args}, (data) => {
            setSelected([])
            fetchNoPrefix();
        }, (er) => {
            failedNotification("Niet gelukt.", "Claimen mislukt, probeer later opnieuw.");
        })

    }

    return { clients, selected, loading, updateClients, selectClient };

}