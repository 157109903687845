import React, { useEffect, useState } from 'react';
import Banner from '../../../assets/components/Banner';
import Footer from '../../../assets/components/Footer';
import { Button, FormGroup, Input, Spinner } from 'reactstrap';
import { makeStyles, Typography } from '@material-ui/core';
import { fetchGet, fetchPost } from '../../../toolbox/fetchlib';
import { Dropdown } from '../../../assets/components/CustomItems';
import { useSelector } from 'react-redux';
import { failedNotification } from '../../../toolbox/notification-center';

const post_creds = "/api/post_user_info";

const useStyles = makeStyles({
    creds: {
        margin: '0 auto',
        width: '98%',
        maxWidth: 500
    },
    creds_fields: {
        width: '100%',
        maxWidth: 230,
        margin: '30px auto 0'
    },
    nr: {
        width: '48%',
        marginRight: '2%',
        display: 'inline-block'
    },
    add: {
        width: '48%',
        marginLeft: '2%',
        display: 'inline-block'
    }
})

export default function BoilerAccount(props) {

    const classes = useStyles();

    const [installers, setInstallers] = useState([]);
    const [error, setError] = useState(false);

    const [phone_number, setPhoneNumber] = useState("");
    const [street, setStreet] = useState("");
    const [nr, setNr] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [loading, setLoading] = useState(false);

    const postCreds = () => {
        if (street === "" || nr === "" || zip === "" || city === "")
            setError(true);
        else {
            setLoading(true);
            fetchPost(post_creds, {
                phone_number: phone_number,
                street: street,
                number: nr,
                zip: zip,
                city: city
            }, (data) => {
                props.dataChanged(`${zip} - ${nr}`);
            }, (er) => {
                failedNotification("Opslaan mislukt.", "Kan gegevens nu niet opslaan. Probeer het later opnieuw.");
             })
        }
    }

    return (
        <div className="full-page">
            <Banner />
            <div id="system_info">
                <div className={classes.creds}>
                    <Typography variant="body1" className="b1">Voer hieronder uw gegevens in:</Typography>
                    <div className={classes.creds_fields}>
                        <FormGroup>
                            <Input
                                id="phone_number"
                                className={(error && phone_number === "") ? "is-invalid" : ""}
                                placeholder="Telefoon"
                                type="text"
                                onKeyUp={(e) => setPhoneNumber(document.getElementById('phone_number').value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="street"
                                className={(error && street === "") ? "is-invalid" : ""}
                                placeholder="Straatnaam"
                                type="text"
                                onKeyUp={(e) => setStreet(document.getElementById('street').value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Input
                                id="nr"
                                className={(error && nr === "") ? "is-invalid" : ""}
                                placeholder="Huisnummer + toevoeging"
                                type="text"
                                onKeyUp={(e) => setNr(document.getElementById('nr').value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="zip"
                                placeholder="Postcode: 1234 AB"
                                type="text"
                                className={(error && zip === "") ? "is-invalid" : ""}
                                onKeyUp={(e) => setZip(document.getElementById('zip').value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="city"
                                placeholder="Plaats"
                                className={(error && city === "") ? "is-invalid" : ""}
                                type="text"
                                onKeyUp={(e) => setCity(document.getElementById('city').value)}
                            />
                        </FormGroup>
                        <Button color="warning"
                            type="button"
                            style={{ width: '100%', marginTop: 20 }}
                            onClick={() => postCreds()}>
                            {loading ? <Spinner style={{ width: 21, height: 21 }} /> : "Opslaan"}</Button>
                    </div>
                </div>
            </div>
        </div >
    )

}