import { Redirect } from 'react-router-dom';
import * as fake from './fakedata.js';

const getFake = (call, params) => {
  const fakes = {
    "/api/topics": fake.getTopics(),
    "/api/data": fake.getData(),
    "/api/clients": fake.getClients(),
    "/api/login": fake.login(),
    "/api/get_account_info": fake.getAccountInfo(),
    "/api/get_system_info": fake.getSystemInfo(),
    "/api/get_installers": fake.getInstallers(),
    "/api/get_externals": fake.getExternal(),
    "/api/client_account_info": fake.getClientInfo(),
    "/api/customers": fake.clients(),
    "/api/noprefix_client_ids": fake.getNoPrefix(),
    "/api/customer_account_info": fake.getCustomerAccInfo(),
    "/api/all_my_client_ids": fake.myClientIds(),
    "/api/systems_online": fake.systemsOnline(),
    "/api/get_hidden_client_ids": fake.getHidden(),
    "/api/get_manual_installer_links": fake.getManualInstaller(),
    "/api/get_notes": fake.getNotes()
  };
  if (call in fakes)
    return (fakes[call]);
  if (call.includes("/api/data"))
    return fakes["/api/data"];
  if (call.includes("/api/get_system_info"))
    return fakes["/api/get_system_info"];
  return {};
}

export const fetchGet = (call, callback, errCallback) => {
  if (window.location.host.includes("localhost")) {
    callback(getFake(call));
  } else {
    fetch(call + (call.includes("?") ? "&src=FECTUM" : "?src=FECTUM"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (response.status < 300) {
          response.json().then(data => {
            callback(data);
          });
        } else {
          if (errCallback === null) callback({ error: true });
          else errCallback(response);
        }
      });
  }
}

export const fetchPost = (call, params, callback, errCallback, offlineCallback) => {
  if (window.location.host.includes("localhost")) {
    let data = getFake(call, params);
    if (data !== null)
      callback(data);
    else
      callback({})
    if (typeof offlineCallback !== 'undefined')
      offlineCallback();
  } else {
    fetch(call + (call.includes("?") ? "&src=FECTUM" : "?src=FECTUM"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        if (response.status < 300) {
          response.json().then(data => {
            callback(data);
          });
        } else {
          if (errCallback === null) callback({ error: true });
          else errCallback(response);
        }
      });
  }
}
