import userEvent from '@testing-library/user-event';
import React, { useEffect, useState } from 'react';
import { fetchGet, fetchPost } from '../../toolbox/fetchlib';
import { failedNotification, successNotification } from '../../toolbox/notification-center';

const getClients = "/api/noprefix_client_ids";
const getAllClients = "/api/all_my_client_ids";
const getCustomers = "/api/customers";
const disconnect = "/api/disconnect_smartline";
const get_installers = "/api/get_installers";
const get_externals = "/api/get_externals";
const topicCall = "/api/topics";
const changeClient = "/api/post_user_info";
const post_creds = "/api/connect_smartline";
const delete_user = "/api/delete_users";
const get_status_post = "/api/systems_online";
const hide_ids = "/api/hide_client_ids";
const get_hidden = "/api/get_hidden_client_ids";
const change_pw_external = "/api/change_password_external";
const manual_installer_link = "/api/manual_installer_link";
const manual_external_link = "/api/manual_external_link";
const manual_set = "/api/get_manual_installer_links";
const manual_external_set = "/api/get_manual_external_links";
const unlink_manual = "/api/delete_manual_installer_link";
const unlink_manual_external = "/api/delete_manual_external_link";
const store_note = "/api/store_note";
const get_notes = "/api/get_notes";

const desiredTopics = ["temp01", "temp02", "temp03", "temp04", "pump01pwm"];

function assignData(client, customers) {
    client.street = "-";
    client.number = "-";
    client.zip = "-";
    client.city = "-";
    client.phone_number = "-";
    client.email = "-";
    client.user_id = "";
    client.trace_number = {};
    client.name = "";
    for (var i = 0; i < customers.length; i++) {
        for (var j = 0; j < customers[i].client_ids.length; j++) {
            if (customers[i].client_ids[j].client_id === client.client_id) {
                var c = customers[i];
                client.email = c.email  || "-";
                client.installer = c.client_ids[j].installer;
                client.number = c.number === null ? "-" : (c.number || "-");
                client.street = c.street === null ? "-" : ((c.street || "-") + client.number !== "-" ? ` ${client.number}` : "");
                client.address = `${c.street || ""} ${c.number || ""}`
                client.zip = c.zip === null ? "-" : (c.zip || "-");
                client.city = c.city === null ? "-" : (c.city || "-");
                client.user_id = c.id;
                client.allow_contact = true;
                client.phone_number = c.phone_number || "-";
                client.name = c.name || "-";
                client.system_name = c.client_ids[j].name;
                if ("trace_number" in customers[i].client_ids[j]) {
                    client.trace_number = customers[i].client_ids[j].trace_number;
                }
                return;
            }
        }
    }
}

function setNrOfTraceNumbers(clients, systems) {
    for (let i=0; i<clients.length; i++) {
        for (let j=0; j<clients[i].client_ids.length; j++) {
            let found = false;
            for (let k=0; k<systems.length; k++) {
                if (clients[i].client_ids[j].client_id === systems[k].client_id) {
                    if ("trace_number" in systems[k] && Object.keys(systems[k].trace_number).length > 0) {
                        var nr = 0;
                        Object.keys(systems[k].trace_number).map((tn) => {
                            if (systems[k].trace_number[tn] !== "")
                                nr++;
                        })
                        clients[i].client_ids[j].nr_of_trace_numbers = nr;
                        found = true;
                    } 
                }
            }
            if (!found)
                clients[i].client_ids[j].nr_of_trace_numbers = 0;
        }
    }
    return clients;
}

export default function AdminData(props) {

    const [loading, setLoading] = useState(false);
    const [system_data, setSystemData] = useState([]);
    const [cust_data, setCustData] = useState([]);
    const [installers, setInstallers] = useState([]);
    const [installers_dict, setInstallersDict] = useState({});
    const [external, setExternal] = useState([]);
    const [external_dict, setExternalDict] = useState({});
    const [topics, setTopics] = useState([]);
    const [reset_table, setResetTable] = useState(false);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchInstallers = () => {
        fetchGet(get_installers, (data) => {
            setInstallers(data.installers);
            let new_dict = {};
            data.installers.map((inst) => {
                new_dict[inst.id] = {
                    email: inst.email,
                    name: inst.name,
                    location: inst.location,
                    phone_number: inst.phone_number,
                    contact_person: inst.contact_person || ""
                }
            });
            setInstallersDict(new_dict);
        }, (er) => { })
    }

    const fetchExternal = () => {
        fetchGet(get_externals, (data) => {
            setExternal(data.externals);
            let new_dict = {};
            data.externals.map((inst) => {
                new_dict[inst.id] = {
                    email: inst.email,
                    name: inst.name,
                    location: inst.location,
                    phone_number: inst.phone_number,
                    contact_person: inst.contact_person || "",
                    rol: inst.rol
                }
            });
            setExternalDict(new_dict);
        }, (er) => { })
    }

    const fetchTopics = () => {
        fetchGet(topicCall, (resp) => {
            var new_topics = [];
            resp.topics.map((topic) => {
                var split = topic.split("/");
                if (desiredTopics.includes(split[split.length - 1])) new_topics.push({ name: split[split.length - 1], param: topic });
            })
            setTopics(new_topics);
        }, () => { console.log("can't fetch topics") })
    }

    const fetchData = () => {
        setLoading(true);
        fetchGet(getClients, (data) => {
            data.map((el) => el.assigned = "Niet toegewezen");
            fetchAll(data);
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan systemen niet ophalen. Probeer het later opnieuw");
        })
    }

    const fetchAll = (prefix) => {
        fetchGet(getAllClients, (data) => {
            data.map((el) => el.assigned = "Solesta");
            fetchCustomers(prefix.concat(data));
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan systemen niet ophalen. Probeer het later opnieuw");
        })
    }

    const fetchCustomers = (prev_data) => {
        fetchGet(getCustomers, (data) => {
            prev_data.map((client_id) => assignData(client_id, data));
            setCustData(setNrOfTraceNumbers(data, prev_data));
            var client_ids = prev_data.map((item) => item.client_id);
            fetchClientIdOnline(client_ids, prev_data);
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan klant niet ophalen. Probeer het later opnieuw");
        })
    }

    const fetchClientIdOnline = (ids, prev_data) => {
        fetchPost(get_status_post, {client_ids: ids}, (data) => {
            prev_data.map((cl) => cl.last_updated = (data[cl.client_id] || {}).last || -1)
            fetchHidden(prev_data);
        }, (er) => { failedNotification("Mislukt.", "Kan online statussen niet ophalen.") })
    }

    
    const fetchHidden = (prev_data) => {
        fetchGet(get_hidden, (data) => {
            prev_data.map((sys) => sys.hide = data.includes(sys.client_id));
            fetchManuallyset(prev_data);
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan systemen niet ophalen. Probeer het later opnieuw");
        })
    }

    const fetchManuallyset = (prev_data) => {
        fetchGet(manual_set, (data) => {
            prev_data.map((dp) => {
                if (dp.client_id in data) {
                    dp.street = data[dp.client_id].street;
                    dp.number = data[dp.client_id].number;
                    dp.zip = data[dp.client_id].zip;
                    dp.city = data[dp.client_id].city;
                    dp.installer = data[dp.client_id].installer;
                    dp.manual_installer = true;
                }
            })
            fetchManualExternalSet(prev_data);
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan handmatig gekoppelde systemen niet ophalen. Probeer het later opnieuw");
        })
    }

    const fetchManualExternalSet = (prev_data) => {
        fetchGet(manual_external_set, (data) => {
            prev_data.map((dp) => {
                if (dp.client_id in data)
                    dp.manual_external = data[dp.client_id].external
            })
            setSystemData(prev_data);
            setResetTable(true);
            setLoading(false);
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan handmatig gekoppelde systemen niet ophalen. Probeer het later opnieuw");
        })
    }

    const unlink = (client) => {
        fetchPost(disconnect, {
            client_id: client.client_id,
            id: client.user_id
        }, (data) => {
            fetchData();
        }, (er) => { failedNotification("Unlink mislukt.", "Probeer het later opnieuw."); });
    }

    const unlinkManual = (client, callback) => {
        fetchPost(unlink_manual, {client_id: client.client_id}, (data) => {
            callback();
            fetchData();
        }, (er) => { failedNotification("Unlink mislukt.", "Probeer het later opnieuw."); })
    }

    const unlinkManualExternal = (client, callback) => {
        fetchPost(unlink_manual_external, {client_id: client.client_id}, (data) => {
            callback();
            fetchData();
        }, (er) => { failedNotification("Unlink mislukt.", "Probeer het later opnieuw."); })
    }

    const updateClient = (client) => {
        fetchPost(changeClient, client, (data) => {
            fetchData();
        }, (er) => {
            failedNotification("Opslaan mislukt", "Kan nu niet opslaan. Probeer het later opnieuw.");
        })
    }

    const saveSystem = (system, callback) => {
        console.log(system)
        fetchPost(post_creds, system, (data) => {
            if (data.success) {
                fetchData();
                callback();
            }
            else if (data.status === 1)
                failedNotification("Apparaat ID niet gevonden.", "Apparaat ID bestaat niet. Controleer of dit veld juist is ingevuld.")
            else if (data.status === 2)
                failedNotification("Apparaat ID al gekoppeld.", "Apparaat ID al gekoppeld aan een ander apparaat. Neem contact op met Solesta.")
            else
                failedNotification("Koppelen mislukt.", "Probeer het later opnieuw.");
        }, (er) => { failedNotification("Koppelen mislukt.", "Probeer het later opnieuw."); })
    }

    const savePw = (pw, id) => {
        fetchPost(change_pw_external, {new_password: pw, id: id}, (data) => {
            successNotification("Wachtwoord gewijzigd", "")
        }, (er) => {
            if (er.status === 450)
                failedNotification("Niet gelukt", "Wachtwoord moet minimaal 8 karakters bevatten waaronder een hoofdletter, speciaal teken en cijfer.")
            else 
                failedNotification("Niet gelukt", "Probeer het later opniew.")
        })
    }

    const deleteUser = (id) => {
        fetchPost(delete_user, {ids: [id]}, (data) => {
            fetchData();
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan klant niet verwijderen. Probeer het later opnieuw.")
        })
    }

    const toggleHide = (id, hide) => {
        fetchPost(hide_ids, {client_ids: {[id]: hide}}, (data) => {
            fetchData();
        }, (er) => {
            failedNotification("Niet gelukt.", "Verbergen mislukt, probeer later opnieuw.");
        })
    }

    const saveSystemLink = (args, callback) => {
        fetchPost(manual_installer_link, args, (data) => {
            callback();
            fetchData();
        }, (er) => {
            failedNotification("Niet gelukt.", "Koppeling mislukt, probeer later opnieuw.");
        })
    }

    const saveExternalLink = (args, callback) => {
        fetchPost(manual_external_link, args, (data) => {
            callback();
            fetchData();
        }, (er) => {
            failedNotification("Niet gelukt.", "Koppeling mislukt, probeer later opnieuw.");
        })
    }

    const storeNote = (args, action, callback) => {
        fetchPost(store_note, args, (data) => {
            fetchData();
            if (typeof callback !== 'undefined')
                callback();
            successNotification("Opgeslagen.", `Notitie is ${action}.`)
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan notitie niet opslaan.")
        })
    }

    return {
        system_data,
        installers,
        external,
        installers_dict,
        external_dict,
        topics,
        cust_data,
        loading,
        reset_table,
        fetchData,
        unlink,
        unlinkManual,
        unlinkManualExternal,
        fetchInstallers,
        fetchExternal,
        fetchTopics,
        updateClient,
        deleteUser,
        saveSystem,
        savePw,
        toggleHide,
        saveSystemLink,
        saveExternalLink,
        storeNote
    };

}