import { useEffect, useState } from "react";
import { fetchPost } from "../../../../toolbox/fetchlib";
import { failedNotification } from "../../../../toolbox/notification-center";

const postNewExternal = "/api/add_external";
const postDelete = "/api/delete_users";
const edit_external = "/api/edit_external";

export const ExternalData = () => {

    const newExternal = (args, callback, error_callback) => {
        fetchPost(postNewExternal, args, (data) => {
            callback();
        }, (er) => {
            error_callback(er.message);
        })
    }

    const deleteExternal = (targets, callback) => {
        fetchPost(postDelete, {ids: targets}, (data) => {
            callback();
        }, (er) => {
            failedNotification("Niet gelukt.", "Kan woningcorporatie niet verwijderen. Probeer het later opnieuw.")
        })
    }

    const editExternal = (creds, callback) => {
        fetchPost(edit_external, creds, (data) => {
            callback();
        }, (er) => {
            if (er.status === 450 )
                failedNotification("Niet gelukt.", "Wachtwoord moet minimaal 8 karakters bevatten waaronder een hoofdletter, speciaal teken en cijfer.")
            else
                failedNotification("Niet gelukt.", "Kan woningcorporatie niet aanpassen. Probeer het later opnieuw.")
        })
    }

    return { 
        newExternal,
        deleteExternal,
        editExternal
    };

}