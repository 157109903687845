import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Button, FormGroup, Input, Spinner } from 'reactstrap';

import logo from './../../assets/img/fectum-logo.png';
import { fetchPost } from '../../toolbox/fetchlib';

const reset_api = "/api/request_pass_reset"

const useStyles = makeStyles({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    logo: {
        width: '98%',
        maxWidth: 300
    },
    fields: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '5vh'
    },
    new_acc_btn: {
        marginTop: '5vh'
    }
});

export default function ForgotPassword (props) {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const [email, setEmail] = useState("");

    const history = useHistory();

    const sendEmail = () => {
        fetchPost(reset_api, {email: email}, (data) => {
            setMessage("sent");
        }, (er) => {setMessage("error"); setLoading(false)})
    } //  /reset-wachtwoord

    return (
        <div className={classes.root}>
            <img src={logo} className={classes.logo} alt="logo" />
            <div className={classes.fields}>
                <Typography style={{textAlign: 'center', marginBottom: 10}}>
                    {message === "error" ? "E-mail onbekend" :  message === "sent" ? "Reset link verzonden naar uw e-mail adres." : ""}
                </Typography>
                {message !== "sent" &&
                <FormGroup>
                    <Input
                        id="uname"
                        value={email}
                        onChange={(v) => setEmail(v.target.value)}
                        placeholder="naam@voorbeeld.nl"
                        type="email"
                    />
                </FormGroup>}
                {message !== "sent" && 
                <Button color="info"
                    type="button"
                    disabled={loading}
                    style={{ width: '100%' }}
                    onClick={() => sendEmail()}>
                    {loading ? <Spinner style={{ width: 21, height: 21 }} /> : "Versturen"}</Button>}
                <Button color="info"
                    type="button"
                    disabled={loading}
                    outline
                    className={classes.new_acc_btn}
                    onClick={() => history.push("/login")}>
                    Terug
                </Button>
            </div>

        </div>
    )
}