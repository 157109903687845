import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Dropdown } from '../../../../assets/components/CustomItems';


function filter(data, search) {
    if (search === "")
        return data;
    return data.filter((dp) => (((dp.street || "") !== null ? (dp.street || "") : "") +
        ((dp.number || "") !== null ? (dp.number || "") : "") +
        ((dp.zip || "") !== null ? (dp.zip || "") : "") +
        ((dp.city || "") !== null ? (dp.city || "") : "")).toLowerCase().includes(search.replace(/\s/g, "").toLowerCase()))
}

function findInstaller(installers, id) {
    for (var i = 0; i < installers.length; i++)
        if (installers[i].id === id)
            return installers[i].name;
    return "Geen installateur"
}

function descendingComparator(a, b, orderBy, order) {
    if (b[orderBy] === "-")
        return order === "asc" ? 1 : -1;
    if (a[orderBy] === "-")
        return order === "asc" ? -1 : 1;

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, order)
        : (a, b) => -descendingComparator(a, b, orderBy, order);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles({
    dropdowndiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    table_actions: {
        width: 260
    }
})

export default function ClientsTable(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [open_ids_set, setOpenIdsSet] = useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('zip');

    const classes = useStyles();

    const [open_ids, setOpenIds] = useState({});

    useEffect(() => {
        if (props.data.length && !open_ids_set) {
            var new_ids = {};
            props.data.map((item) => new_ids[item.id] = item.client_ids.length ? 0 : -1);
            setOpenIdsSet(true);
            setOpenIds(new_ids);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.reset_table)
            setOpenIds({});
    }, [props.reset_table])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const changeOpenIds = (id, val) => {
        setOpenIds(cur => {
            var new_open = { ...cur };
            new_open[id] = val;
            return new_open;
        });
    }

    const sortClick = (key) => {
        if (key === orderBy && order === "asc") {
            setOrder("desc");
        } else {
            setOrderBy(key);
            setOrder('asc');
        }
    }

    console.log(props);

    return (
        <Paper className="table-root" variant="outlined">
            <TableContainer className="table-container">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-cell">
                                Apparaat ID
                            </TableCell>
                            <TableCell className="table-cell">
                                Trace
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "name"}
                                    direction={orderBy === "name" ? order : 'asc'}
                                    onClick={() => sortClick("name")}>
                                    Naam
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "email"}
                                    direction={orderBy === "email" ? order : 'asc'}
                                    onClick={() => sortClick("email")}>
                                    Email
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                Telefoonnummer
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "street"}
                                    direction={orderBy === "street" ? order : 'asc'}
                                    onClick={() => sortClick("street")}>
                                    Straat
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "zip"}
                                    direction={orderBy === "zip" ? order : 'asc'}
                                    onClick={() => sortClick("zip")}>
                                    Postcode
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-cell">
                                <TableSortLabel active={orderBy === "city"}
                                    direction={orderBy === "city" ? order : 'asc'}
                                    onClick={() => sortClick("city")}>
                                    Plaats
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: 190, fontWeight: 'bold' }} align="center">
                                Acties
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filter(stableSort(props.data, getComparator(order, orderBy)), props.search).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                            if (open_ids_set)
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                        <TableCell>
                                            {row.client_ids.length ? <Dropdown
                                                options={row.client_ids.map((clid) => { return { label: clid.name, key: clid.client_id } })}
                                                onChange={(v) => changeOpenIds(row.id, v)}
                                                value={open_ids[row.id]}
                                                color="primary"
                                                style={{ width: 210 }}
                                                title="Systemen"
                                            /> : <Typography variant="h6" style={{ width: 210, textAlign: 'center', fontSize: 18 }}>Geen systeem</Typography>}
                                        </TableCell>
                                        <TableCell>
                                            {open_ids[row.id] >= 0 ? row.client_ids[open_ids[row.id]].nr_of_trace_numbers : 0 }
                                        </TableCell>
                                        <TableCell>
                                            {row.name || ""}
                                        </TableCell>
                                        <TableCell>
                                            {row.email || ""}
                                        </TableCell>
                                        <TableCell>
                                            {"phone_number" in row ?
                                                row.phone_number.length === 10 ? `${row.phone_number.replace(/\s/g, '').substr(0, 3)} ${row.phone_number.replace(/\s/g, '').substr(3, 3)} ${row.phone_number.replace(/\s/g, '').substr(6, 4)}` :
                                                    row.phone_number : "Niet beschikbaar"}
                                        </TableCell>
                                        <TableCell>
                                            {`${row.street || ""} ${row.number || ""}`}
                                        </TableCell>
                                        <TableCell>
                                            {row.zip || ""}
                                        </TableCell>
                                        <TableCell>
                                            {row.city || ""}
                                        </TableCell>
                                        <TableCell className={`table-actions ${classes.table_actions}`}>
                                            <Button size="sm"
                                                id={`view-btn-${(i + page * rowsPerPage)}`}
                                                onClick={() => (row.client_ids || []).length ? window.open(("/systeem-status#" + row.client_ids[0].client_id), "_blank") : console.log('todo')}
                                                color="info"
                                                disabled={row.client_ids.length === 0}>
                                                <i className="fa fa-eye"></i>
                                            </Button>
                                            <Button size="sm"
                                                id={`chart-btn-${(i + page * rowsPerPage)}`}
                                                color="default"
                                                disabled={row.client_ids.length === 0}
                                                onClick={() => (row.client_ids || []).length ? props.chartData(row.client_ids[0].client_id, row) : console.log('todo')}>
                                                <i className="ni ni-chart-bar-32"></i>
                                            </Button>
                                            <Tooltip title="Klantgegevens aanpassen">
                                                <Button size="sm"
                                                    id={`edit-btn-${(i + page * rowsPerPage)}`}
                                                    color="primary"
                                                    onClick={() => props.editClient(row)}>
                                                    <i className="fa fa-edit"></i>
                                                </Button>
                                            </Tooltip>
                                            <Button size="sm"
                                                id={`edit-btn-${(i + page * rowsPerPage)}`}
                                                color="warning"
                                                disabled={row.client_ids.length === 0}
                                                onClick={() => props.editSystem(row.client_ids[open_ids[row.id]], `${row.zip} - ${row.number}`, row.id)}>
                                                <i className="fa fa-tasks"></i>
                                            </Button>
                                            <Tooltip title="New systeem">
                                                <Button size="sm"
                                                    id={`edit-btn-${(i + page * rowsPerPage)}`}
                                                    color="success"
                                                    onClick={() => props.editSystem(null, `${row.zip} - ${row.number}`, row.id)}>
                                                    <i className="fa fa-link"></i>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Verwijderen">
                                                <Button size="sm"
                                                    id={`edit-btn-${(i + page * rowsPerPage)}`}
                                                    color="danger"
                                                    onClick={() => props.deleteUser(row.id)}>
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 75, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
