import { Divider, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal
} from "reactstrap";
import { Dropdown } from '../../../../assets/components/CustomItems';
import { jsonEmpty } from './../../../../toolbox/utils';


const useStyles = makeStyles({

})

export default function ClientModal(props) {

    const classes = useStyles();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [street, setStreet] = useState("");
    const [nr, setNr] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [pw, setPw] = useState("");

    useEffect(() => {
        if (typeof props.client !== 'undefined' && !jsonEmpty(props.client) && props.client.user_id !== "") {
            setPhone(props.client.phone_number === "-" ? "" : props.client.phone_number);
            setStreet(props.client.street === "-" ? "" : props.client.street);
            setNr(props.client.number === "-" ? "" : props.client.number);
            setZip(props.client.zip === "-" ? "" : props.client.zip);
            setCity(props.client.city === "-" ? "" : props.client.city);
        }
    }, [props.client])

    const saveClient = () => {
        if ("id" in props.client)
            props.saveClient({
                id: props.client.id,
                name: name !== "" ? name : props.client.name,
                phone_number: phone !== "" ? phone : props.client.phone_number,
                street: street !== "" ? street : props.client.street,
                number: nr !== "" ? nr : props.client.number,
                zip: zip !== "" ? zip : props.client.zip,
                city: city !== "" ? city : props.client.city
            });
        else
            props.saveClient({
                name: name !== "" ? name : props.client.name,
                phone_number: phone !== "" ? phone : props.client.phone_number,
                street: street !== "" ? street : props.client.street,
                number: nr !== "" ? nr : props.client.number,
                zip: zip !== "" ? zip : props.client.zip,
                city: city !== "" ? city : props.client.city
            });
        props.close();
    }

    return (
        <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={props.open}
            toggle={() => props.close()}
        >
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            {props.text}
                        </div>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Naam"}
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Telefoonnummer"}
                                        type="text"
                                        id="phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Straat"}
                                        type="text"
                                        value={street}
                                        id="street"
                                        onChange={(e) => setStreet(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className={`input-group-alternative ${classes.nr}`}>
                                    <Input placeholder={"Huisnummer + toevoeging"}
                                        type="text"
                                        value={nr}
                                        id="number"
                                        onChange={(e) => setNr(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Postcode"}
                                        type="text"
                                        value={zip}
                                        id="cip"
                                        onChange={(e) => setZip(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Plaats"}
                                        type="text"
                                        value={city}
                                        id="city"
                                        onChange={(e) => setCity(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                                <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    style={{ width: 130 }}
                                    onClick={() => saveClient()}
                                >
                                    Opslaan
                                </Button>
                            </div>
                            <Divider style={{marginBottom: 25}}/>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input placeholder={"Wachtwoord"}
                                        type="text"
                                        id="city"
                                        value={pw}
                                        onChange={(e) => setPw(e.target.value)} />
                                </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                                <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    style={{ width: 130 }}
                                    onClick={() => props.savePw(pw, props.client.id)}
                                >
                                    Opslaan
                                </Button>
                                <Button
                                    className="my-4"
                                    type="button"
                                    style={{ width: 130 }}
                                    onClick={() => props.close()}
                                >
                                    Annuleren
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    )
}